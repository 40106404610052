import styled from '@emotion/styled'
import MuxPlayer from '@mux/mux-player-react'
import clsx from 'clsx'
import qs from 'qs'
import { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams, useParams, useNavigate } from 'react-router-dom'

import { useVideoParams } from './hooks/use-video-params'
import ProcessingFailed from './sections/processing-failed'
import ProcessingProgress from './sections/processing-progress'

import { Highlights } from '@/components/highlights'
import { CircularLoadingIndicator } from '@/components/loading-indicator/CircularLoadingIndicator'
import { TabView } from '@/components/tabview'
import { TitleBar } from '@/components/titlebar'
import { Toggle } from '@/components/toggle'
import { VideoOverlay } from '@/components/video-overlay'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useURLParams } from '@/hooks/use-url-params'
import { sawVideo, useVideoExcerpt } from '@/store/library'
import { useAlertBanner } from '@/store/providers/alert-banner-provider'
import { useGetInsights, useGetVideo, useGetVideoMetadata } from '@/store/video'
import { isProd } from '@/utils'
import { APIContext, APIWrapper } from '@/utils/api'
import COLORS from '@/utils/colors'
import { formatDate, formatWithTimeConfig } from '@/utils/helper'

const THEATER_MODE_WIDTH = '80vw !important'

function InsightsPage () {
  const params = useParams()
  const searchParams = useSearchParams()[0]
  const { showAlert } = useAlertBanner()

  const vid = params.vid
  const workflowId = searchParams.get('workflowId')
  const getInsightsArgs = { vid }
  if (workflowId) {
    getInsightsArgs.workflowId = workflowId
  }
  useEffect(() => {
    if (!isProd) {
      showAlert({
        id: 'development-server-warning',
        permanent: true,
        dismissibleOnMobile: true,
        title: 'You are on the Test Server',
        message:
          'This environment is unstable and will often change. Data is periodically deleted.'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <APIWrapper
      apis={[
        { api: useGetVideo, args: vid, waitForLoad: true, name: 'video', needsUseAPI: false },
        { api: useGetInsights, args: getInsightsArgs, waitForLoad: false, name: 'insights', needsUseAPI: false },
        { api: useGetVideoMetadata, args: vid, waitForLoad: true, name: 'videoMetadata', needsUseAPI: false }
      ]}
      addContext={ctx => {
        // if the insights data isn't the right version, don't try to use it
        if (ctx.insights) {
          ctx.insightsVersion = ctx.insights.version
        }

        // add workflow to context (extracted from video object to be the one
        // that was requested by the user)
        const { workflows } = ctx.video
        if (!workflowId) {
          // workflows[0] could be undefined if no workflow has started yet
          const latestWorkflow = workflows[0]
          return { workflow: latestWorkflow, ...ctx }
        }
        for (const workflow of workflows) {
          if (workflow.workflowId === workflowId) {
            return { workflow, ...ctx }
          }
        }
        return ctx // workflow not found
      }}
    >
      <InsightsContainer />
    </APIWrapper>
  )
}

const Container = styled('div')({
  maxWidth: '100vw',
  // width: '100vw !important', // this is causing the page to be wider than the viewport
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .video-container': {
    maxWidth: '880px',
    width: '100% !important',
    padding: '15px',
    '& .solo-poster': {
      width: '100%'
    },
    '&.theater-mode': {
      width: THEATER_MODE_WIDTH,
      maxWidth: THEATER_MODE_WIDTH
    },
    '& .mux-container': {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
      '& .mux-container__video': {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        height: '100%'
      },
      '& mux-player': {
        '--time-display': 'block',
        '--duration-display': 'block'
      },
      '& .mux-footer': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: COLORS['neutral-100'],
        borderRadius: '0 0 8px 8px',
        padding: '8px 16px',
        minHeight: 45,
        '& .rally-number': {
          color: COLORS['neutral-700'],
          fontFamily: 'Inter',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '166%'
        }
      },
      '& .theater-mode-toggle': {
        position: 'absolute',
        bottom: '11px',
        right: '134px',
        cursor: 'pointer',
        fontSize: '24px',
        color: 'white',
        zIndex: 1
      }
    }
  },
  '& .insights-container': {
    maxWidth: '880px',
    width: '100% !important',
    padding: '15px'
  },
  '&.mobile': {
    width: '100% !important',
    '& .solo-poster': {
      marginTop: '15px'
    }
  }
})

function InsightsContainer () {
  // this container won't be rendered until video is populated, but it will
  // be potentially rendered before insights is available (insights may not
  // ever become ready if they fail to be compute by the server! or they may
  // just be computed way later if server-side processing is still in progress)
  const { insights, video, workflow, videoMetadata } = useContext(APIContext)
  const isMobile = useMobileDetect()
  const { handleWithVideoParams } = useVideoParams()
  const { updateSearchParams } = useURLParams()
  const dispatch = useDispatch()
  const muxPlayerRef = useRef(null)
  const theaterModeToggleRef = useRef(null)
  const params = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const vid = params.vid
  const { videoExcerpt } = useVideoExcerpt(vid)
  const shadowRefs = useRef()
  const [isProcessingFailed, setIsProcessingFailed] = useState(false)
  const [isTheaterMode, setIsTheaterMode] = useState(false)

  const isPosterReady = !video.noPoster
  const hasServerComputedInsights = Boolean(workflow && !workflow?.noInsights)
  const isVideoReadyToStream = Boolean(!video.mux?.notReady)
  const isWorkflowDone = Boolean(workflow?.epochFinished)
  const isWorkflowStarted = Boolean(workflow)

  shadowRefs.current = {
    isTheaterMode,
    setIsTheaterMode
  }

  useEffect(() => {
    // When url is shared with state = explore, redirect the user to /video/[vid]/explore page
    const queryState = searchParams.get('state')
    try {
      const query = JSON.parse(decodeURI(queryState))
      if (query.path === 'explore') {
        const { path, ...filters } = query
        navigate({ pathname: `/video/${vid}/explore`, search: qs.stringify(filters) }, { replace: true })
      }
    } catch (err) {
      console.error(`Query string ${queryState} not recognized.`)
    }
  }, [navigate, searchParams, vid])

  useEffect(() => {
    if (videoExcerpt?.new) {
      dispatch(sawVideo(vid))
    }
  }, [dispatch, vid, videoExcerpt?.new])

  const rallies = insights?.rallies

  function jumpToVideoTime (secsInOriginalVideo) {
    const muxPlayer = muxPlayerRef.current
    if (muxPlayer) {
      if (muxPlayer.paused || muxPlayer.ended) {
        muxPlayer.play()
      }
      updateSearchParams('t', secsInOriginalVideo)
      muxPlayer.currentTime = secsInOriginalVideo
    }
  }

  useEffect(() => {
    const muxPlayer = muxPlayerRef.current

    if (muxPlayer) {
      handleWithVideoParams(muxPlayer)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muxPlayerRef.current])

  function onPlay () {
    registerTheaterModeControl()
  }

  function isFullScreen () {
    return !!(
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    )
  }

  function registerTheaterModeControl () {
    if (theaterModeToggleRef.current) return
    // retrieve the elements we need to manipulate
    const muxPlayerEl = document.querySelector('mux-player')
    const muxMediaEl = muxPlayerEl.shadowRoot.querySelector('media-theme')
    const controllerEl = muxMediaEl.shadowRoot.querySelector('media-controller')
    const controlBarEl = controllerEl.querySelectorAll('media-control-bar')[1]
    if (!controlBarEl) {
      return
    }
    const fullScreenBtn = controlBarEl.querySelector('media-fullscreen-button')

    // overflow fix, known issue with mux-player / media-chrome (https://github.com/muxinc/elements/issues/865)
    const mediaThemeStyles = controlBarEl.shadowRoot.ownerDocument.createElement('style')
    mediaThemeStyles.innerHTML = `
      media-theme {
        overflow: hidden;
      }
    `
    muxPlayerEl.shadowRoot.insertBefore(mediaThemeStyles, muxPlayerEl.shadowRoot.firstChild)
    // add styles for the theater mode toggle
    const theaterModeToggleStyles = controlBarEl.shadowRoot.ownerDocument.createElement('style')
    theaterModeToggleStyles.innerHTML = `
      theater-mode-toggle:hover {
        background-color: #13d208;
      }
    `
    muxMediaEl.shadowRoot.insertBefore(theaterModeToggleStyles, muxMediaEl.shadowRoot.firstChild)
    // create the theater mode toggle
    const theaterModeToggle = muxMediaEl.shadowRoot.ownerDocument.createElement('theater-mode-toggle')
    theaterModeToggle.style = 'width: 30px; height: 26px; border-radius: 4px; cursor: pointer; display: flex; justify-content: center; align-items: center'
    const toggleIcon = muxMediaEl.shadowRoot.ownerDocument.createElement('theater-mode-toggle-icon')
    toggleIcon.style = 'width: 18px; height: 14px; border: 1px solid white; border-radius: 4px'
    theaterModeToggle.appendChild(toggleIcon)

    theaterModeToggle.onclick = () => {
      const { isTheaterMode, setIsTheaterMode } = shadowRefs.current
      if (isFullScreen()) {
        document.exitFullscreen()
        setIsTheaterMode(true)
      } else {
        try {
          document.exitPictureInPicture()
        } catch {
          // ignore: the function throws if picture-in-picture isn't present
        }
        setIsTheaterMode(!isTheaterMode)
      }
    }
    controlBarEl.insertBefore(theaterModeToggle, fullScreenBtn)
    theaterModeToggleRef.current = theaterModeToggle
  }

  function listenForRateChangeAndUpdateParams () {
    const currentRate = muxPlayerRef.current.playbackRate

    updateSearchParams('speed', currentRate)
  }

  const [skipDeadTime, setSkipDeadTime] = useState(true)
  const [rallyNumber, setRallyNumber] = useState(1)
  useEffect(() => {
    const extraMillisBeforeStart = 1000 // play this amount before the rally starts
    const extraMillisAfterEnd = 2000 // play for this amount beyond the rally ends
    const millisBeforeToWait = 2000 // don't jump unless more than 2sec of dead time
    let lastMillis = 0 // to track the last time checked for rewind detection

    const intervalId = setInterval(() => {
      const muxPlayer = muxPlayerRef.current
      if (!muxPlayer || !rallies || muxPlayer.paused) {
        return
      }

      let curMillis = muxPlayer.currentTime * 1000

      // Check for rewind action. If the user rewinds into dead time,
      // take them to the previous rally instead of returning them to the current rally.

      const currentRally = rallies[rallyNumber - 1]
      if (!currentRally) {
        return
      }
      // Check does user rewind inside current rally, and when break into the dead time
      // continue to the next step to take them back to the previous rally
      if (curMillis < lastMillis && curMillis < currentRally.start_ms) { // Check if current time is less than last recorded time (rewind action)
        // After the first rally, we can go back to the previous rally
        if (rallyNumber > 1) {
          const previousRally = rallies[rallyNumber - 2]
          // Calculate current time ignoring dead time
          const newCurrentTime = previousRally.end_ms - (currentRally.start_ms - curMillis)
          muxPlayer.currentTime = newCurrentTime / 1000
          curMillis = newCurrentTime
          lastMillis = curMillis
          return
        }
      }

      lastMillis = curMillis // Update lastMillis for the next interval check

      // find the earliest rally which has not yet finished (could binary
      // search or even pre-compute this so it's much faster)
      // Note: To pre-compute, compute the rally that should be viewed at every
      //       0.1sec interval; then can just lookup in that table to see which
      //       rally we should be for any given moment (values are either null
      //       meaning don't skip, or the time to skip to).
      let nextUnfinished, newRallyNum
      for (let i = 0; i < rallies.length; i++) {
        const rally = rallies[i]
        if (curMillis <= rally.end_ms + extraMillisAfterEnd) {
          nextUnfinished = rally
          newRallyNum = i + 1
          break
        }
      }
      if (rallyNumber !== newRallyNum) {
        setRallyNumber(newRallyNum)
      }

      if (!skipDeadTime) {
        return
      }
      if (!nextUnfinished) {
        muxPlayer.pause()
        return
      }

      // if it's millisBeforeToWait or more until nextUnfinished starts, then
      // skip to it
      const millisUntilStart = nextUnfinished.start_ms - extraMillisBeforeStart - curMillis
      if (millisUntilStart >= millisBeforeToWait) {
        const newTime = (nextUnfinished.start_ms - extraMillisBeforeStart) / 1000
        muxPlayer.currentTime = newTime
      } else {
        // if we're in the middle of the nextUnfinished OR it's less than
        // millisBeforeToWait until the next rally, then just wait
      }
    }, 500) // increased interval to prevent play issues on IOS devices
    return () => clearInterval(intervalId)
  }, [rallies, rallyNumber, skipDeadTime])

  // Handle keydown events to change the video current time
  useEffect(() => {
    let intervalId = null

    const handleKeyDown = (event) => {
      if ((event.key === 'ArrowLeft' || event.key === 'ArrowRight') && !intervalId) {
        // Start interval only if it's not already running
        const player = muxPlayerRef.current
        if (player) {
          player.pause()
          intervalId = setInterval(() => {
            switch (event.key) {
              case 'ArrowLeft':
                player.currentTime -= 1 / 30
                break
              case 'ArrowRight':
                player.currentTime += 1 / 30
                break
              default:
                break
            }
          }, 100) // Adjust position every 100ms
        }
      }
    }

    const handleKeyUp = (event) => {
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        clearInterval(intervalId) // Clear the interval when the key is released
        intervalId = null // Reset the interval ID
      }
    }

    // Add event listener to document to capture all key presses
    // You can also attach this to a specific component if required
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  function getVideoSection ({ width, height }) {
    const aspectRatio = width / height
    const posterURL = `https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${vid}/poster.jpg`
    if (isVideoReadyToStream) {
      return (
        <>
          <div className='mux-container' style={{ aspectRatio }}>
            <div className='mux-container__video'>
              <MuxPlayer
                hotkeys='noarrowleft noarrowright'
                playbackId={video.mux?.playbackId}
                placeholder={posterURL}
                playbackRates={[0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 2.0, 3.0]}
                accent-color={COLORS['primary-500']}
                onPlay={onPlay}
                ref={muxPlayerRef}
                forward-seek-offset='5'
                backward-seek-offset='5'
                onRateChange={listenForRateChangeAndUpdateParams}
                style={{ aspectRatio }}
              />
              <VideoOverlay
                muxPlayerRef={muxPlayerRef}
                rallyNumber={rallyNumber}
                vid={vid}
              />
            </div>
            {!isProcessingFailed && (
              <div className='mux-footer'>
                <span className='rally-number'>
                  {/* Rally #{rallyNumber} */}
                </span>
                <Toggle
                  checked={skipDeadTime}
                  onChange={(checked) => setSkipDeadTime(checked)}
                  label='Remove Idle Time'
                  tooltip='Automatically skip time in between rallies.'
                />
              </div>
            )}
          </div>
          <Highlights
            videoExcerpt={videoExcerpt}
            userData={video.userData}
            insights={insights}
            onHighlightClicked={highlight => jumpToVideoTime(highlight.s / 1000)}
          />
        </>
      )
    }
    if (isPosterReady) {
      return <img className='solo-poster' src={posterURL} />
    }
    if (!isWorkflowDone) {
      return (
        <VideoPlaceholder>
          <CircularLoadingIndicator label='This video was recently uploaded. We’re still getting your video ready to watch.' />
        </VideoPlaceholder>
      )
    }
    // If we get here, then the video isn't being processed anymore. Another
    // part of the page will describe what went wrong.
  }

  function getBottomSection () {
    if (hasServerComputedInsights) {
      if (!insights) {
        // API to load insights data hasn't returned the data yet
        return (
          <CircularLoadingIndicator label='Loading insights...' estimatedSecsToFinish={2} />
        )
      }

      // otherwise, show the tabs breaking showing all the insights data
      return <TabView video={video} insights={insights} workflow={workflow} />
    } else {
      if (isWorkflowDone) {
        // if workflow is done but insights isn't ready, then processing failed
        !isProcessingFailed && setIsProcessingFailed(true)
        return <ProcessingFailed video={video} workflow={workflow} />
      } else if (!isWorkflowStarted) {
        // no workflow has started yet (but one should start soon, it happens a
        // few seconds after the video is uploaded)
        return (
          <CircularLoadingIndicator label='Our AI will start processing your video shortly.' estimatedSecsToFinish={5} />
        )
      }
    }
  }

  return (
    <Container className={clsx([{ mobile: isMobile }])}>
      <div className={clsx(['video-container', { 'theater-mode': isTheaterMode }])}>
        <TitleBar
          name={videoExcerpt?.name ?? video.userData.name}
          date={formatDate(video.userData.gameStartEpoch, formatWithTimeConfig, undefined, true)}
          video={video}
        />
        {getVideoSection(videoMetadata.metadata)}
      </div>
      {workflow && !isWorkflowDone && (
        <ProcessingProgress
          vid={vid}
          workflowId={workflow.workflowId}
          workflowIndex={0}
        />
      )}
      <div className='insights-container'>
        {getBottomSection()}
      </div>
    </Container>
  )
}

export default InsightsPage

export const VideoPlaceholder = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: COLORS['neutral-300'],
  width: '100%',
  aspectRatio: '2/1'
})
