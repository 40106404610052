import { styled } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PageContext } from '../context'

import Divider from '@/assets/breadcrumb-divider.svg?react'
import PublicFolderIcon from '@/assets/icons/public-folder-icon.svg?react'
import ShareFolderIcon from '@/assets/icons/share-icon-outlined.svg?react'
import { SimpleButton } from '@/components/button/simple-button'
import { TextButton } from '@/components/button/text-button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import COLORS from '@/utils/colors'

export default function Breadcrumb () {
  const isMobile = useMobileDetect()
  const navigate = useNavigate()
  const { uid } = useParams()
  const { folders, selectedFolder, setIsShareDialogOpen, isRootFolderPublic, isInPublicLibrary } = useContext(PageContext)
  const [selectedFolderObject, setSelectedFolderObject] = useState()

  const isPublic = Boolean(selectedFolderObject?.public)

  function onNavigateToFolder (fid = false) {
    let link = '/library/'

    if (isInPublicLibrary) {
      link += `public/${uid}/`
    }

    if (fid) {
      link += fid
    }
    navigate(link)
  }

  useEffect(() => {
    const object = folders?.filter(folder => folder.fid === Number(selectedFolder))

    object && setSelectedFolderObject(...object)
  }, [folders, selectedFolder])

  return (
    <Container className={isMobile && 'mobile'}>
      <div className='current-folder'>
        {!isInPublicLibrary &&
          <SimpleButton onClick={() => setIsShareDialogOpen({ root: { public: isRootFolderPublic } })}> {isRootFolderPublic ? <PublicFolderIcon /> : <ShareFolderIcon />}</SimpleButton>}
        <TextButton onClick={() => onNavigateToFolder()}>Video Library</TextButton>
      </div>
      {selectedFolderObject && (
        <Divider />
      )}
      {selectedFolderObject?.parent && (
        <>
          ...
          <Divider />
        </>
      )}
      {selectedFolderObject && (
        <div className='current-folder'>
          {!isRootFolderPublic && !isInPublicLibrary &&
            <SimpleButton onClick={() => setIsShareDialogOpen(selectedFolderObject)}> {isPublic ? <PublicFolderIcon /> : <ShareFolderIcon />}</SimpleButton>}
          <p>{selectedFolderObject?.name}</p>
        </div>
      )}
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '8px',
  fontSize: '24px',
  fontWeight: 600,
  color: COLORS.black,
  '& .current-folder': {
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  '&.mobile': {
    fontSize: '20px'
  }
})
