import COLORS from '@/utils/colors'

export const shotTypes = [{ value: 'smash', label: 'Smash' }, { value: 'lob', label: 'Lob' }, { value: 'dink', label: 'Dink' }, { value: 'drop', label: 'Drop' }, { value: 'drive', label: 'Drive' }, { value: 'atp', label: 'ATP' }, { value: 'erne', label: 'Erne' }, { value: 'tweener', label: 'Tweener' }]
export const rallySequence = [{ value: 'serve', label: 'Serve' }, { value: 'return', label: 'Return' }, { value: '3', label: '3rd' }, { value: '4', label: '4th' }, { value: '5', label: '5th' }, { value: 'final', label: 'Last' }]
export const rallySequenceValues = rallySequence.map((r) => r.value)
export const customTags = ['Ready position', 'Dinking', 'Backhand volley dink']
export const highlights = [{ value: 'long_rally', label: 'Long rally' }, { value: 'atp', label: 'Atp' }, { value: 'erne', label: 'Erne' }]
export const strokeSides = [{ value: 'right', label: 'Forehand' }, { value: 'left', label: 'Backhand' }, { value: null, label: 'Both' }]
export const shotWindowMarks = [...Array(7).keys()].map((k) => {
  const curr = k - 3
  if (curr > 0) {
    return { value: curr, label: `+${curr}` }
  } else if (curr < 0) {
    return { value: curr, label: curr }
  } else {
    return { value: 0, label: 'Shot' }
  }
})

export const players = [...Array(4).keys()]
export const playerColors = {
  '& .player1': { color: COLORS.player1 },
  '& .player2': { color: COLORS.player2 },
  '& .player3': { color: COLORS.player3 },
  '& .player4': { color: COLORS.player4 }
}
export const shotTypeQuantity = shotTypes.concat(rallySequence).reduce((acc, c) => {
  acc[c.value] = 0
  return acc
}, {})

const arrayFilters = ['characteristics', 'customTags', 'highlights', 'sequences', 'types', 'directions']
export const diffFilters = (filters) => {
  const diff = {}
  arrayFilters.forEach((prop) => {
    if (filters[prop].length) Object.assign(diff, { [prop]: filters[prop] })
  })
  if (filters.strokeSide !== null) Object.assign(diff, { strokeSide: filters.strokeSide })
  if (filters.quality.min !== 0 || filters.quality.max !== 1) Object.assign(diff, { quality: filters.quality })
  if (filters.shotWindow.numBefore !== -1 || filters.shotWindow.numAfter !== 1) Object.assign(diff, { shotWindow: filters.shotWindow })
  if (!filters.players.length || players.some((p) => !filters.players.includes(p))) Object.assign(diff, { players: filters.players })
  return diff
}
