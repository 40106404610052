import { styled } from '@mui/material'
import clsx from 'clsx'
import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PageContext } from '../context'
import { useBulkSelect } from '../hooks/use-bulk-select'

import FolderContextMenu from './folder-context-menu'

import FolderBackside from '@/assets/folder-backside.svg?react'
import FolderFrontside from '@/assets/folder-frontside.svg?react'
import PublicFolderIcon from '@/assets/icons/public-folder-icon.svg?react'
import TransparentCheckbox from '@/components/checkbox/transparent-checkbox'
import { Tooltip } from '@/components/tooltip'
import useMobileDetect from '@/hooks/use-mobile-detect'
import COLORS from '@/utils/colors'

export default function Folder (props) {
  const isMobile = useMobileDetect()
  const params = useParams()
  const navigate = useNavigate()
  const isTablet = useMobileDetect('tablet')
  const { videoExcerpts, isInPublicLibrary, isRootFolderPublic, bulkSelected, setBulkSelected } = useContext(PageContext)
  const { toggleItemSelection, isItemSelected } = useBulkSelect(bulkSelected)

  const isSelected = isItemSelected({ fid: props.fid })

  function findVideoInFolderQuery (video) {
    if (video.vid) {
      return video.fid === props.fid
    }
    return false
  }

  const addItemToBulkSelected = () => {
    const selected = toggleItemSelection(props)
    setBulkSelected(selected)
  }

  const latestVideo = videoExcerpts && videoExcerpts.find(video => findVideoInFolderQuery(video))

  const sourceURL = `https://storage.googleapis.com/${import.meta.env.VITE_PRO_BUCKET}/${latestVideo?.vid}/thumbnail.jpg`
  const defaultImage = 'https://storage.googleapis.com/pbv-pro-dev/assets/open-graph-preview2.png'
  const urls = [sourceURL, defaultImage].filter(Boolean)

  function onNavigateToFolder () {
    if (isInPublicLibrary) {
      navigate(`/library/public/${params.uid}/${props.fid}`)
    } else {
      navigate(`/library/${props.fid}`)
    }
  }

  const isPublic = isRootFolderPublic || (!isInPublicLibrary && props?.public)

  return (
    <Container className={clsx(isTablet && 'tablet', isMobile && 'mobile', isSelected && 'selected')} onClick={onNavigateToFolder}>
      <div className='folder-icon'>
        <FolderBackside className='folder-backside' />
        <FolderFrontside className='folder-frontside' />
        {latestVideo?.vid &&
          <div
            className='image'
            style={{
              backgroundImage: `${urls.map(url => `url('${url}')`).join(', ')}`
            }}
          />}
      </div>
      <div className='options'>
        {isPublic && <Tooltip title='Folder is public' css_class='tooltip'><PublicFolderIcon /></Tooltip>}
        <p>{props?.name}</p>
        {!isInPublicLibrary &&
          <FolderContextMenu className='menu-button' folder={props} />}
      </div>
      <TransparentCheckbox onChange={addItemToBulkSelected} checked={isSelected} visible={Boolean(bulkSelected?.length)} />
    </Container>
  )
}

export const thumbPhoneWidth = '100%'
export const thumbTabletWidth = '48%'
export const thumbDesktopWidth = '356px'

const Container = styled('div')({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  padding: '16px 16px 12px 16px',
  width: thumbDesktopWidth,
  aspectRatio: '1.3/1',
  border: `1px solid ${COLORS['neutral-200']}`,
  borderRadius: '6px',
  textDecoration: 'none',
  backgroundColor: COLORS['neutral-100'],
  cursor: 'pointer',
  '&.selected': {
    border: `1px solid ${COLORS['primary-500']}`
  },

  '&:hover': {
    '& .folder-icon .image': {
      transform: 'translateX(-50%) translateY(-60%) rotateZ(0deg)'
    },
    '& .transparent-checkbox': {
      display: 'flex'
    }
  },
  '& .transparent-checkbox': {
    position: 'absolute',
    top: 0,
    left: 0
  },
  '& .folder-icon': {
    position: 'relative',
    height: '75%',
    width: '100%',
    '& svg, .image': {
      position: 'absolute',
      transform: 'translateX(-50%) translateY(-50%) scale(1.2)',
      '&.folder-backside': {
        zIndex: 1,
        left: '50%',
        top: '50%'
      },

      '&.folder-frontside': {
        zIndex: 3,
        top: '61%',
        left: '52%'
      }
    },
    '& .image': {
      zIndex: 2,
      transform: 'translateX(-50%) translateY(-50%) rotateZ(7deg)',
      left: '58%',
      top: '26%',
      width: '110px',
      height: '80px',
      backgroundSize: 'cover',
      borderRadius: '6px',
      transition: '300ms'
    }
  },
  '& .options': {
    display: 'flex',
    gap: '8px',
    flex: 1,
    alignItems: 'center',
    '& svg': {
      width: '24px',
      height: '24px'
    },
    '& span': {
      height: '24px'
    },
    '& p': {
      fontWeight: 600,
      fontSize: '16px',
      marginRight: 'auto',
      lineHeight: '24px',
      color: COLORS['neutral-800'],
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },

  '&.mobile': {
    width: thumbPhoneWidth,
    '& .MuiFormControl-root': {
      maxWidth: '170px !important'
    }
  },
  '&.tablet': {
    width: thumbTabletWidth
  }
})
