import { Box, MenuItem, Select, SvgIcon, styled } from '@mui/material'
import * as Sentry from '@sentry/react'
import React, { createContext, useContext, useEffect, useState } from 'react'

import HelpIcon from '@/assets/help.svg?react'
import ShotNumberIcon from '@/assets/icon-shot-number.svg?react'
import { Percentile } from '@/components/percentile'
import { ShotsViewer, normalizeDirection } from '@/components/shots-viewer'
import { Tooltip } from '@/components/tooltip'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { use3DViewerShots } from '@/hooks/use-shots-for-3d-viewer'
import { useFilteredShotsWithContext } from '@/hooks/use-shots-with-context'
import COLORS from '@/utils/colors'
import { row, column } from '@/utils/flexGrid'

const SHOTS_ARRAY = ['Serves', 'Returns', '3rd Shots', '4th Shots', '5th Shots']
const SHOTS_TYPES = [{ id: 'all', label: 'All Shots' }, { id: 'drop', label: 'Drops' }, { id: 'drive', label: 'Drives' }, { id: 'lobs', label: 'Lobs' }]
const THIRD_SHOTS_TOOLTIPS = {
  all: 'Quality estimates how well a shot is executed. Choose a specific shot type from the dropdown and this tooltip will explain how quality is computed for that shot type.',
  drop: 'Quality estimates how well a shot is executed. Drops are evaluated based on how attackable the ball would be if the receiver was perfectly situated on their kitchen line.',
  drive: 'Quality estimates how well a shot is executed. Drives are evaluated based on how high over the net the ball flies (lower is better) and how deep in the court the ball lands.',
  lob: 'Quality estimates how well a shot is executed. Lobs are evaluated based on how deep in the court the ball lands (deeper is better).'
}

const generateTotalShotsLabel = (count, shotNumber, shotType) => {
  let label = 'Total '

  if (shotType !== 'all') {
    label += `${SHOTS_ARRAY[shotNumber - 1].slice(0, -1)} ${SHOTS_TYPES.find(shot => shot.id === shotType).label}`
  } else {
    label += SHOTS_ARRAY[shotNumber - 1]
  }

  label += `: ${count}`

  return label
}

const calculateQuality = (stats, selected) => {
  let total = 0
  const collected = selected.reduce((acc, cur) => {
    let now = acc
    if (stats[cur]) {
      stats[cur].forEach((bt) => {
        if (Object.prototype.hasOwnProperty.call(bt, 'quality')) {
          total += 1
          now += bt.quality
        }
      })
    }
    return now
  }, 0)
  return collected ? (collected / total).toFixed(1) * 100 : 0
}

const getStats = (rallies, shotNumberSelection) => {
  const stats = Array.from({ length: 4 }, () => ({}))
  for (let r = 0; r < rallies.length; r++) {
    const rally = rallies[r]
    if (Array.isArray(rally.moments)) {
      let shotNumber = 0
      for (let m = 0; m < rally.moments.length; m++) {
        const moment = rally.moments[m]
        const shot = moment.ball?.shot
        if (shot?.resulting_ball_movement) {
          shotNumber += 1
          if (shotNumber === shotNumberSelection) {
            // This is now third shot
            if (!stats[shot.player_id][shot.shot_type]) {
              stats[shot.player_id][shot.shot_type] = []
            }
            if (shot.resulting_ball_movement.trajectory) {
              stats[shot.player_id][shot.shot_type].push({
                ...shot.resulting_ball_movement.trajectory,
                // Used for quality calculation
                ...(shot.quality && Object.prototype.hasOwnProperty.call(shot.quality, 'overall')
                  ? { quality: shot.quality.overall }
                  : {})
              })
            }
            // Each rally has only 1 shotNumberSelection [Serve/Return/3rd ..etc] shot, break and iterate to the next rally
            break
          }
        }
      }
    }
  }
  return stats
}

function Avg (props) {
  const { value, label = 'Avg height over net', suffix = '”' } = props
  return value > 0
    ? (
      <div className='average'>
        {label}:
        <div>
          {value}
          {suffix}
        </div>
      </div>
      )
    : null
}

const countAvg = (stats, selected, type = 'avg_height_above_net') => {
  let times = 0
  const total = selected.reduce((acc, cur) => {
    if (stats[`${cur}s`]?.[type]) {
      times += 1
      return acc + stats[`${cur}s`][type]
    }
    return acc
  }, 0)
  return (total / times).toFixed(1)
}

const getSelectedShots = (val) => {
  if (val === 'all') return ['drive', 'drop', 'lob']
  return [val]
}

const initial3rdShots = Array.from({ length: 4 }, () => 'all')

export const PlayerStatsContext = createContext(null)

export default function SelectedShotsViewer (props) {
  const isMobile = useMobileDetect()
  const playerIdx = props.playerIdx
  // 3rd shots
  const [thirdShots, setThirdShots] = useState(initial3rdShots)
  const insights = props.insights

  const handleThirdShots = (index, value) => {
    setThirdShots({
      ...thirdShots,
      [index]: value
    })
  }

  // Extract 3rd shot stats from rallies
  const stats = getStats(insights.rallies, props.shotNumberSelection)

  const shotQuality = calculateQuality(stats[playerIdx], getSelectedShots(thirdShots[playerIdx]))

  const [shotCounts, setShotCounts] = useState(0)

  const updateShotCount = (count) => {
    setShotCounts(count)
  }

  useEffect(() => {
    if (props.shotNumberSelection <= 2) {
      handleThirdShots(playerIdx, 'all')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shotNumberSelection])

  return (
    <PlayerStatsContext.Provider value={{ shotCounts, updateShotCount }}>
      <Container className={isMobile && 'mobile'}>
        <div className='viewer-navigation'>
          <div className='selects'>
            <Select
              value={props.shotNumberSelection}
              className='single-select'
              label=''
              onChange={props.onShotNumberSelectionChange}
              renderValue={value =>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <SvgIcon color='primary'>
                    <ShotNumberIcon />
                  </SvgIcon>
                  <span>
                    {SHOTS_ARRAY[value - 1]}
                  </span>
                </Box>}
            >
              {SHOTS_ARRAY.map((label, i) => (
                <MenuItem key={i} value={i + 1}>{label}</MenuItem>
              ))}
            </Select>
            {props.shotNumberSelection > 2 &&
              <Select
                value={thirdShots[playerIdx]}
                className='single-select'
                label=''
                onChange={(e) => handleThirdShots(playerIdx, e.target.value)}
              >
                {SHOTS_TYPES.map((type, i) => (
                  <MenuItem value={type.id} key={i}>{type.label}</MenuItem>
                ))}
              </Select>}
          </div>
          <div className='quality-viewer'>
            {shotQuality !== 0 && (
              <>
                <p className='quality-viewer-title'>Shot Quality:</p>
                <Percentile
                  className='quality-viewer-progress'
                  sx={{ color: COLORS['neutral-800'], flex: 1 }}
                  value={shotQuality}
                  suffix='%'
                />

              </>
            )}
            {shotQuality !== 0 &&
              <Tooltip title={THIRD_SHOTS_TOOLTIPS[thirdShots[playerIdx]]}>
                <HelpIcon />
              </Tooltip>}
          </div>
          <div className='total'>
            {generateTotalShotsLabel(shotCounts, props.shotNumberSelection, thirdShots[playerIdx])}
          </div>
          <div>
            {['drop', 'drive'].includes(thirdShots[playerIdx]) && (
              <Avg
                label='Median Height Above Net'
                suffix='ft'
                value={countAvg(
                  insights.player_stats[playerIdx].third_shot_stats,
                  getSelectedShots(thirdShots[playerIdx]),
                  'median_height_above_net'
                )}
              />
            )}
            {thirdShots[playerIdx] === 'lob' && (
              <Avg
                label='Median Baseline Distance'
                value={countAvg(
                  insights.player_stats[playerIdx].third_shot_stats,
                  getSelectedShots(thirdShots[playerIdx]),
                  'median_baseline_distance'
                )}
              />
            )}
          </div>
        </div>
        <div className='viewer-container'>
          <ShotsViewerShotsProvider
            insights={insights}
            playerIdx={playerIdx}
            shotTypesToInclude={new Set(getSelectedShots(thirdShots[playerIdx]))}
            shotNumberInRally={props.shotNumberSelection}
            viewerProps={{
              camera: null,
              onCameraChanged: null
            }}
          />
        </div>
      </Container>
    </PlayerStatsContext.Provider>
  )
}

/**
 * Returns a selection of 3rd shots hit by the specified player.
 *
 * @param {object} [insights] the insights object; okay to omit if not yet loaded
 * @param {int} playerId which player's 3rd shots should be extracted
 * @param {Set<string>} shotTypesToInclude which shots to include
 * @param {int} [shotNumberInRally=3] which shot number in the rally to include (e.g., 1 = serves, 2 = returns, etc.)
 * @returns {Array<ShotWithContext>} selected shots and context about them
 */
export function useNthShotsForPlayerWithContext (insights, playerId, shotTypesToInclude, shotNumberInRally = 3) {
  const desiredShotIdx = shotNumberInRally - 1
  return useFilteredShotsWithContext(insights, shotWithContext => {
    const { shotIdx, shot } = shotWithContext
    let badShot
    if (shot.resulting_ball_movement) {
      if (desiredShotIdx === 0) {
      /*
       * Remove serves that start within the court y boundary by more than 5 feet.
       *  - Ex: resulting_ball_movement.trajectory.start.location.y that is lesser than 39 (increasing) or greater than 5 (decreasing)
       * Remove serves that contain resulting_ball_movement.is_volleyed set to true
       */
        if (shot.resulting_ball_movement.is_volleyed === true) {
          badShot = true
        } else {
          const yStart = shot.resulting_ball_movement.trajectory.start.location.y
          const yEnd = shot.resulting_ball_movement.trajectory.end.location.y
          if (yStart <= yEnd) {
          // Increasing shot
            badShot = yStart > 5
          } else {
          // Decreasing shot (yStart > yEnd)
            badShot = yStart < 39
          }
        }
      } else if (desiredShotIdx === 1) {
      /*
       * Remove returns that contain resulting_ball_movement.is_volleyed set to true
       */
        badShot = shot.resulting_ball_movement.is_volleyed === true
      }
    }
    return (
      !badShot && shotIdx === desiredShotIdx &&
        shot.player_id === playerId &&
        shotTypesToInclude.has(shot.shot_type)
    )
  })
}

function SimpleError ({ error, message }) {
  useEffect(() => {
    console.error(error)
  }, [error])
  return (
    <div style={{
      display: 'flex',
      height: '100%',
      width: '100%',
      placeContent: 'center',
      alignItems: 'center',
      background: '#f8ecec',
      color: '#811'
    }}
    >
      <div style={{ maxWidth: '70%' }}>
        {message || 'Sorry! An error occurred while rendering the Shots Viewer.'}
      </div>
    </div>
  )
}

function ShotsViewerShotsProvider ({ insights, playerIdx, shotTypesToInclude, shotNumberInRally, viewerProps }) {
  const { updateShotCount } = useContext(PlayerStatsContext)
  const shotsWithContext = useNthShotsForPlayerWithContext(insights, playerIdx, shotTypesToInclude, shotNumberInRally)
  const shots = use3DViewerShots(shotsWithContext)

  useEffect(() => {
    updateShotCount(shots.length)
  }, [shots.length, updateShotCount])

  return shots.length < 1000
    ? (
      <Sentry.ErrorBoundary fallbackRender={({ error }) => <SimpleError error={error} />}>
        <ShotsViewer
          shots={shots.map(normalizeDirection)}
          playerId={playerIdx + 1}
          {...viewerProps}
        />
      </Sentry.ErrorBoundary>
      )
    : <SimpleError message='Too many shots selected. Please change your selection and try again.' />
}

const Container = styled('div')({
  background: COLORS.white,
  border: `1px solid ${COLORS['neutral-300']}`,
  color: COLORS.black,
  padding: 32,
  '& .viewer-navigation': {
    ...row,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > div': {
      width: '50%',
      marginBottom: 20
    },
    '& .selects': {
      ...row,
      gap: 15,
      '& .single-select': {
        backgroundColor: COLORS['neutral-050'],
        '& .MuiSelect-select': {
          padding: '8px 30px 8px 8px'
        }
      }
    },
    '& .total': {
      color: COLORS['neutral-500'],
      fontWeight: 'bold',
      fontSize: 14
    },
    '& .average': {
      color: COLORS['neutral-500'],
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      margin: 0,
      '& > div': {
        marginLeft: '0.25em',
        fontWeight: 600
      }
    },
    '& .quality-viewer': {
      ...row,
      alignItems: 'center',
      gap: 8,
      '& .quality-viewer-title': {
        color: COLORS['neutral-500'],
        fontSize: 14
      },
      '& .percentile': {
        width: 220
      }
    }
  },
  '& .viewer-container': {
    height: 'auto',
    aspectRatio: 1.7455357143,
    overflow: 'hidden'
  },
  '&.mobile': {
    ...column,
    padding: 15,
    '& .viewer-navigation': {
      ...column,
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& > div': {
        width: '100%',
        marginBottom: 10
      }
    },
    '& .viewer-container': {
      aspectRatio: 1
    }
  }
})
