import { styled } from '@mui/material/styles'
import { useEffect } from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'

import Sidebar from './sidebar'

import useMobileDetect from '@/hooks/use-mobile-detect'
import { useIsAuthInitialized, useIsLoggedIn } from '@/store/auth'

const Container = styled('div')({
  display: 'flex !important',
  paddingTop: '24px !important',
  paddingBottom: '0 !important',
  '& .innerContainer': {
    display: 'flex',
    minHeight: 'calc(100vh - 72px)',

    '& .content': {
      flex: 1
    }
  }
})

function Settings () {
  const isMobile = useMobileDetect()
  const location = useLocation()
  const { pathname } = location
  const navigate = useNavigate()

  const isAuthReady = useIsAuthInitialized()
  const isLoggedIn = useIsLoggedIn()

  useEffect(() => {
    if (isAuthReady && !isLoggedIn) {
      navigate('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthReady, isLoggedIn])

  useEffect(() => {
    if (pathname === '/settings') {
      navigate('/settings/account')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <Container>
      <div className='innerContainer'>
        {isLoggedIn && (
          <>
            {!isMobile && <Sidebar />}
            <div className='content'>
              <Outlet />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}

export default Settings
