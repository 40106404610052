import { styled } from '@mui/material'

export const Container = styled('div')({
  display: 'flex',
  padding: '0 !important',
  width: '100% !important',
  maxWidth: '1140px',
  '& .inner-container': {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    padding: '40px 15px 20px',
    minHeight: 'calc(100vh - 48px) !important',
    '& .page-header': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      '& .top-header': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .header-title': {
          fontWeight: '600',
          fontSize: '24px',
          color: '#32383E'
        }
      },
      '& .controls-header': {
        marginTop: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .search-section': {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '10px'
        }
      }
    },
    '& .page-content': {
      marginTop: '15px'
    },
    '& .new-folder-button': {
      alignSelf: 'flex-end',
      marginTop: '5px'
    }
  },
  '& .multiselect-container': {
    height: '0px',
    transition: 'height 0.5s ease',
    '&.active': {
      height: '46px'
    }
  },
  '&.mobile': {
    '& .inner-container': {
      padding: '30px 15px !important'
    },
    '& .header-title': {
      fontSize: '20px !important'
    },
    '& .search-section': {
      width: '100% !important'
    }
  },
  '&.desktop': {
    maxWidth: '95%'
  }
})
