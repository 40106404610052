/**
 * Creates an object composed of the picked object properties that have existing value in the object (not undefined)
 *
 * @param {object} obj The source object
 * @param {Array} paths The property paths to pick
 * @returns {object} object with picked properties
 */
function pick (obj, paths) {
  return { ...paths.reduce((mem, key) => ({ ...mem, ...(obj[key] !== undefined ? { [key]: obj[key] } : {}) }), {}) }
}

export default pick
