import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import PersonIcon from '@mui/icons-material/Person'
import clsx from 'clsx'
import { useState } from 'react'

import styles from './styles.module.scss'

import { TextButton } from '@/components/button/text-button'
import { signOut } from '@/store/auth'

function Sidebar () {
  const items = [
    {
      id: 'account',
      icon: PersonIcon,
      title: 'Account',
      action: () => {}
    }
  ]

  const [activeId, setActiveId] = useState('account')
  // eslint-disable-next-line no-unused-vars
  const [isSigningOut, setIsSigningOut] = useState(false)

  function signOutWrapper () {
    setIsSigningOut(true)
    signOut().finally(() => setIsSigningOut(false))
  }

  const renderItems = () => (
    <>
      {items.map((item) => {
        const { title, icon: Icon, id } = item
        const isActive = id === activeId
        return (
          <div key={id} className={clsx([styles.item, { [styles.active]: isActive }])} onClick={() => setActiveId(id)}>
            <Icon className={styles.icon} />
            <div className={styles.titleSection}>
              <div className={styles.title}>{title}</div>
              <ChevronRightIcon className={styles.icon} />
            </div>
          </div>
        )
      })}
    </>
  )

  return (
    <div className={styles.sidebar}>
      <div className={styles.items}>{renderItems()}</div>
      <TextButton
        className={styles.signOut}
        onClick={() => {
          signOutWrapper()
        }}
      >
        Sign out
      </TextButton>
    </div>
  )
}

export default Sidebar
