import { styled } from '@mui/material/styles'

import { playerColors } from '../helpers'

import COLORS from '@/utils/colors'

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row !important',
  flexGrow: 1,
  height: '100% !important',
  alignItems: 'flexStart',
  width: '100% !important',
  marginTop: -16,
  justifyContent: 'space-between',
  gap: '5%',

  '& .space-padding': {
    // Dummy element that occupies left empty space and is shrunk
    // when not enough horizontal space (flex)
    // flexBasis: '340px'
  },
  '& .wrapper': {
    maxWidth: '848px',
    // width: '100% !important',
    flexGrow: 1,
    marginLeft: 'auto',
    padding: '31px 15px 15px'
  },
  '& .mux-container__video': {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  '&.mobile .wrapper': {
    width: '100% !important',
    '& .solo-poster': {
      marginTop: '15px'
    }
  },
  '& aside': {
    // marginLeft: 'auto',
    // content inside div is 320px, but scrollbar takes up 20px
    minWidth: 340,
    width: 340,
    display: 'flex',
    flexDirection: 'column',
    background: COLORS['neutral-050'],
    zIndex: 10,
    position: 'relative',
    overflow: 'auto',
    '& > div': {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '100%'
    },
    '& header': {
      borderLeft: `1px solid ${COLORS['neutral-200']}`,
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: COLORS['neutral-700'],
      '& .row': {
        width: '100%'
      },
      '& .MuiStack-root': {
        flexWrap: 'wrap',
        gap: '4px',
        '& .MuiChip-root': {
          marginLeft: 0
        }
      },
      '& h3': {
        fontSize: 24,
        fontWeight: 600,
        color: 'inherit',
        marginRight: '8px'
      },
      '& button': {
        marginLeft: 'auto',
        padding: '0 12px',
        maxHeight: 32,
        '& svg': {
          color: 'inherit',
          marginLeft: 0,
          marginRight: 6
        }
      }
    },
    '& .shots': {
      overflow: 'auto'
    },
    '& .no-shots': {
      alignItems: 'center',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      color: COLORS['neutral-700'],
      border: `1px solid ${COLORS['neutral-300']}`,
      marginRight: 8,

      '& > div': {
        textAlign: 'center'
      },
      '& .lead': {
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '166%'
      },
      '& .detail': {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '166%'
      },
      '& button': {
        marginTop: 16,
        background: COLORS['neutral-100']
      }
    },
    '& .rallies': {
      backgroundColor: COLORS['neutral-100'],
      marginBottom: 16,
      '& .title': {
        color: COLORS['neutral-800'],
        background: COLORS['neutral-200'],
        padding: 12,
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 600
      },
      '& .shot': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // gap: 8,
        // padding: 12,
        overflow: 'hidden',
        borderRadius: 8,
        borderWidth: 3,
        borderStyle: 'solid',
        borderColor: 'transparent',
        cursor: 'pointer',
        position: 'relative',

        '& *': {
          flexShrink: 0
        },
        '& .img': {
          marginRight: 8
        },
        '&.selected': {
          background: COLORS['neutral-050'],
          borderColor: 'currentcolor'
        },
        '& .player': {
          marginRight: 8
        },
        '& .quality': {
          // padding here so that tooltip can react on wider area for the dot
          padding: '0 8px',
          alignSelf: 'stretch',
          display: 'flex'
        },
        '& .dot': {
          alignSelf: 'center',
          borderRadius: '100%',
          height: 6,
          width: 6
        },
        '& .time': {
          color: COLORS['neutral-800'],
          width: '40px',
          textAlign: 'center',
          fontSize: 14,
          fontWeight: 300
        },
        '& .name': {
          display: 'flex',
          alignItems: 'center',
          width: 200,
          flex: 1,
          color: COLORS['neutral-800'],
          fontSize: 14,
          fontWeight: 600,

          '& .emojis': {},
          '& .tooltip': {
            flex: '0 1 auto',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
            overflow: 'hidden'
          }
        }
      }
    },
    // Player avatars inside shot rows
    '& .img': {
      borderWidth: 0,
      '& .pi__img': {
        width: '50% !important'
      }
    },
    // Play overlay - covering the player image in shot list
    '& .play-overlay': {
      position: 'absolute',
      left: 0,
      top: 0,
      height: 56,
      width: 56,
      border: 'none',
      background: 'rgba(0,0,0, .3)',
      cursor: 'pointer',
      '& svg': {
        height: 56,
        width: 56,
        fill: '#fff'
      }
    },
    '& .row': {
      display: 'flex',
      flexDirection: 'row'
    },
    ...playerColors
  },
  // Mobile overrides
  '&.mobile': {
    flexDirection: 'column !important',
    flexGrow: 1,
    height: 'unset !important',
    alignItems: 'unset',
    width: 'unset !important',
    marginTop: 'unset',
    justifyContent: 'unset',
    gap: 'unset',

    '& .wrapper': {
      flexGrow: 0,
      '& > .mobile': {
        // Add spacing to titlebar
        marginBottom: 4
      }
    },
    '& .space-padding': {
      display: 'none'
    },
    '& aside': {
      position: 'static',
      right: 'unset',
      top: 'unset',
      bottom: 'unset',
      width: 'calc(100% - 20px)',
      minWidth: 'unset',
      marginLeft: 10,

      '& > div': {
        position: 'static',
        top: 'unset',
        left: 'unset',
        right: 'unset',
        bottom: 'unset',
        width: 'unset'
      }
    }
  }
})
