import { useSearchParams } from 'react-router-dom'

export function useURLParams () {
  const [searchParams, setSearchParams] = useSearchParams()

  function nestParamsIntoURL (baseURL) {
    let shareURL = baseURL
    const parameters = []

    // Check if startTime - 't' parameter exists
    const time = searchParams.get('t')
    if (time) {
      parameters.push(`t=${time}`)
    }

    // Check if duration -  'l' parameter exists
    const duration = searchParams.get('l')
    if (duration) {
      parameters.push(`l=${duration}`)
    }

    // Check if play speed - 'speed' parameter exists
    const speed = searchParams.get('speed')
    if (speed) {
      parameters.push(`speed=${speed}`)
    }

    // Join all parameters with '&' and append to URL
    if (parameters.length > 0) {
      shareURL += '?' + parameters.join('&')
    }

    return shareURL
  }

  function nestCustomParamsIntoURL (baseURL, paramsArray) {
    let shareURL = baseURL
    const parameters = []

    paramsArray.forEach(param => {
      parameters.push(`${param.key}=${param.value}`)
    })

    // Join all parameters with '&' and append to URL
    if (parameters.length > 0) {
      shareURL += '?' + parameters.join('&')
    }

    return shareURL
  }

  function updateSearchParams (key, value) {
    const params = new URLSearchParams(searchParams)
    if (params.has(key)) {
      if (key === 'speed' && Number(value) === 1) {
        params.delete(key)
      } else {
        params.set(key, value)
      }
    } else {
      params.set(key, value)
    }

    setSearchParams(params, { replace: true })
  }

  return { nestParamsIntoURL, updateSearchParams, nestCustomParamsIntoURL }
}
