import { styled } from '@mui/material/styles'

// import COLORS from '@/utils/colors'

/**
 * Avatar from Initials
 *
 * @exports
 * @param props {object} { round, width, initials }
 * @returns {React.ReactElement}
 */
export const Avatar = styled('div', {
  shouldForwardProp: (prop) => !['round', 'width', 'initials'].includes(prop)
})(({ round, width, initials }) => {
  const WIDTH = Number(width || 35)

  return {
    position: 'relative',
    height: `${WIDTH}px`,
    width: `${WIDTH}px`,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',

    '&:before': {
      display: 'block',
      position: 'absolute',
      content: '""',
      width: '100%',
      height: '100%',
      backgroundColor: 'currentcolor',
      zIndex: 0,
      ...(round
        ? {
            borderRadius: '100%'
          }
        : {
            borderRadius: 2.6,
            transform: 'rotate(45deg)',
            transformOrigin: 'center',
            height: WIDTH - WIDTH * 0.14285714285714285,
            width: WIDTH - WIDTH * 0.14285714285714285
          })
    },
    '&:after': {
      content: `"${initials || ''}"`,
      color: '#fff',
      zIndex: 1,
      fontSize: `${WIDTH / 2}px`,
      fontWeight: 500,
      lineHeight: 1,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }
})
