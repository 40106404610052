import { styled } from '@mui/material/styles'

import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { round as roundNum } from '@/utils/helper'

/**
 * Bar chart container
 *
 * @param props {object} { width, vertical } - Width of the chart, Vertical for vertically aligned charts
 * @returns {React.ReactElement}
 */
const BarChartContainer = styled('div', {
  skipProps: ['vertical', 'width']
})(({ width, vertical }) => ({
  display: 'flex',
  flexGrow: 1,

  '& .bg-darker': {
    color: COLORS['neutral-500']
  },
  '& div': {
    display: 'flex',
    color: COLORS.white,
    fontSize: 18.879,
    fontWeight: 500,
    letterSpacing: 0.202,
    alignItems: 'center',
    justifyContent: 'center'
  },
  ...(width ? { width: `${width}px` } : {}),
  ...(vertical
    ? {
        justifyContent: 'flex-end',
        flexDirection: 'column',
        border: `1px solid ${COLORS['neutral-400']}`,
        '& > div': {
          flexDirection: 'column',
          placeContent: 'center'
        }
      }
    : {
        flexDirection: 'row',
        '& > div': {
          overflow: 'hidden',
          '& span': {
            whiteSpace: 'nowrap'
          }
        }
      })
}))

/**
 * BarChart component - vertical or horizontal with single or two values and configurable colors
 *
 * @exports
 * @param {*} props
 * @returns {React.ReactElement}
 */
export function BarChart (props) {
  const {
    vertical,
    single,
    width,
    value1,
    value2,
    sx,
    fill1 = 'transparent',
    fill2 = 'transparent',
    round = true
  } = props
  let val1
  let val2

  // Code is more readable done this way (instead of one-liner)
  if (round) {
    val1 = roundNum(value1 === undefined ? 100 - value2 : value1)
    val2 = roundNum(value2 === undefined ? 100 - value1 : value2)
  } else {
    val1 = value1 === undefined ? 100 - value2 : value1
    val2 = value2 === undefined ? 100 - value1 : value2
  }

  let leftLabel = ''
  let rightLabel = ''
  let isSwapped = false

  // Override labels when single is set - position the label in the larger area
  if (single) {
    // Only second value given and single value should be displayed
    if (value1 === undefined) {
      if (val1 >= 50) {
        // Put the val2 in the left section as it's larger
        leftLabel = `${val2} %`
        isSwapped = true
      } else {
        // val2 in the right section as it's larger
        rightLabel = `${val2} %`
      }
    } else if (value2 === undefined) {
      // Handle the value1 the same way as value2
      if (val2 >= 50) {
        rightLabel = `${val1} %`
        isSwapped = true
      } else {
        leftLabel = `${val1} %`
      }
    }
  } else {
    // Both values should be displayed
    leftLabel = val1 > 14 && `${val1} %`
    rightLabel = val2 > 14 && `${val2} %`
  }

  return (
    <BarChartContainer vertical={vertical} width={width} sx={sx}>
      <div
        style={{
          [vertical ? 'height' : 'width']: `${val1}%`,
          backgroundColor: fill1,
          ...(single ? {} : {})
        }}
      >
        <span className={cls(isSwapped && 'bg-darker')}>{leftLabel}</span>
      </div>
      <div
        style={{
          [vertical ? 'height' : 'width']: `${val2}%`,
          backgroundColor: fill2,
          ...(single ? {} : {})
        }}
      >
        <span className={cls(isSwapped && 'bg-darker')}>{rightLabel}</span>
      </div>
    </BarChartContainer>
  )
}
