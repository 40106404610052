import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { styled } from '@mui/material'
import React from 'react'

import useMobileDetect from '@/hooks/use-mobile-detect'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'

export default function TransparentCheckbox (props) {
  const isMobile = useMobileDetect()

  function onPress (e) {
    e.preventDefault()
    e.stopPropagation()
    props?.onChange && props?.onChange()
  }

  return (
    <Container className={cls('transparent-checkbox', (props.visible || isMobile) && 'visible')} onClick={onPress}>
      <div className='content'>
        {props.checked &&
          <CheckRoundedIcon />}
      </div>
    </Container>
  )
}

const Container = styled('div')({
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80px',
  height: '80px',

  '& .content': {
    backgroundColor: COLORS['neutral-200'],
    borderRadius: '4px',
    width: '24px',
    height: '24px',
    opacity: 0.8,
    boxShadow: '1px 1px 4px #00000040',
    transition: '300ms'
  },
  '&.visible': {
    display: 'flex'
  },
  '&:hover .content': {
    backgroundColor: COLORS.white
  },
  '& svg': {
    color: COLORS['neutral-700']
  }

})
