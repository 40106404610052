/* Avoid throwing errors when @react-three/fiber ThreeElement properties aren't found */
/* eslint react/no-unknown-property: "off" */

import { useGLTF /* , ShadowAlpha */ } from '@react-three/drei'
import React from 'react'

import pickleballCourtModelUrl from '@/assets/shots-viewer/pickleball-court.glb'

useGLTF.preload(pickleballCourtModelUrl)

export function PickleballCourtModel (props) {
  const { nodes /* , materials */ } = useGLTF(pickleballCourtModelUrl)
  return (
    <group {...props} dispose={null} rotation={[0, Math.PI / 2, 0]} scale={3.281}>
      <group name='Court' position={[0, 1.29, 0]}>
        <mesh
          name='Net'
          castShadow
          geometry={nodes['Cube-net'].geometry}
          material={nodes['Cube-net'].material}
          position={[0, -0.874, 0]}
        >
          {/* <ShadowAlpha /> */}
          <mesh
            name='PostRight'
            castShadow
            geometry={nodes.Cylinder.geometry}
            material={nodes.Cylinder.material}
            position={[0, 0.006, 3.298]}
          />
          <mesh
            name='PostLeft'
            castShadow
            geometry={nodes.Cylinder001.geometry}
            material={nodes.Cylinder001.material}
            position={[0, 0.006, -3.311]}
          />
        </mesh>
        <mesh
          name='Lines'
          receiveShadow
          geometry={nodes.lines.geometry}
          material={nodes.lines.material}
          position={[0, -1.328, 0]}
        />
        <mesh
          name='ServiceArea'
          receiveShadow
          geometry={nodes['right-service-area001'].geometry}
          material={nodes['right-service-area001'].material}
          position={[0, -1.329, 0]}
        />
      </group>
    </group>
  )
}
