import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useEffect } from 'react'

import Container, { white, lightBlue, midnightBlue } from './container.jsx'

import howToRecordImageUrl from '@/assets/images/video-tips/how-to-record.gif?url'
import idealFraming2ImageUrl from '@/assets/images/video-tips/ideal-framing-2.jpg?url'
import idealFraming3ImageUrl from '@/assets/images/video-tips/ideal-framing-3.jpg?url'
import idealFramingImageUrl from '@/assets/images/video-tips/ideal-framing.jpg?url'
import notGreatFraming2ImageUrl from '@/assets/images/video-tips/not-great-framing-2.jpg?url'
import notGreatFraming3ImageUrl from '@/assets/images/video-tips/not-great-framing-3.jpg?url'
import notGreatFramingImageUrl from '@/assets/images/video-tips/not-great-framing.jpg?url'
import steadyHandGreenImageUrl from '@/assets/images/video-tips/steady-hand-green.png?url'
import steadyHandRedImageUrl from '@/assets/images/video-tips/steady-hand-red.png?url'
import whiteCircleIconUrl from '@/assets/images/video-tips/white-circle-icon.svg?url'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({
  border: `1px solid ${lightBlue}`,
  backgroundColor: white,
  borderRadius: '8px',
  marginBottom: '16px',
  padding: '20px 24px',
  '&::before': {
    display: 'none'
  }
})

const ExpandIcon = (props) => (
  <div className='white-circle'>
    <img src={whiteCircleIconUrl} loading='lazy' alt='reveal answer' />
  </div>
)

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandIcon />}
    {...props}
  />
))({
  padding: '0',
  backgroundColor: 'unset',
  minHeight: 'unset',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'none'
  },
  '& .MuiAccordionSummary-content': {
    margin: '0'
  },
  '& .MuiTypography-root': {
    margin: '0',
    fontSize: '15px',
    lineHeight: '180%',
    color: midnightBlue,
    textAlign: 'left',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '600',
    '&:hover': {
      color: '#ff7143'
    }
  }
})

const AccordionDetails = styled(MuiAccordionDetails)({
  padding: '0',
  '& .MuiTypography-root': {
    paddingTop: '12px',
    fontWeight: '400',
    fontSize: '15px',
    color: midnightBlue,
    textAlign: 'left',
    fontFamily: 'Inter, sans-serif',
    lineHeight: '180%'
  }
})

const FaqAccordion = (props) => (
  <Accordion>
    <AccordionSummary>
      <Typography>{props.question}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography>
        {props.answer}
      </Typography>
    </AccordionDetails>
  </Accordion>
)

const VideoTipsPage = () => {
  useEffect(() => {
    const mainDiv = document.getElementsByClassName('main')[0]
    mainDiv.classList.add('converted-from-webflow')
    return () => {
      mainDiv.classList.remove('converted-from-webflow')
    }
  }, [])

  return (
    <Container>
      <div className='section'>
        <div className='container w-container'>
          <div className='center-content center-text margin-top-bottom'>
            <h1 className='limit-large'>How to record your gameplay video</h1>
            <div className='medium-text opacity-dark-text limit-small'>The following tips will help ensure we can
              provide you with the best possible results
            </div>
            <div className='div-block-9'>
              <img src={howToRecordImageUrl} loading='lazy' alt='' />
            </div>
          </div>
          <h2 className='heading-4'>Framing the court</h2>
          <div className='medium-text opacity-dark-text limit-large'>
            The camera should be at least 4 feet off the ground and all 4 corners of the court should be easily seen in the video recording window.
            <span className='text-span-4'>&nbsp;This is important! You&apos;ll get best results when the AI can clearly see the entire court.</span>
          </div>
          <div className='w-row'>
            <div className='w-col w-col-4'>
              <div
                className='feature-block'
                style={{
                  opacity: 1,
                  transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d'
                }}
              >
                <img
                  src={idealFramingImageUrl} loading='lazy' width='300' alt='' className='process-image'
                />
                <div className='flex-vertical space-top-large'><h4 className='no-space-bottom'>Ideal framing</h4>
                  <div className='opacity-dark-text'>The full court is in the frame and the camera is high enough off
                    the ground. <span className='text-span-2' />
                  </div>
                </div>
              </div>
            </div>
            <div className='w-col w-col-4'>
              <div
                className='feature-block'
                style={{
                  opacity: 1,
                  transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d'
                }}
              >
                <img
                  src={idealFraming2ImageUrl} loading='lazy' width='300' alt='' className='process-image'
                />
                <div className='flex-vertical space-top-large'><h4 className='no-space-bottom'>Ideal framing</h4>
                  <div className='opacity-dark-text'>The full court is in the frame and the camera is high enough off
                    the ground.
                  </div>
                </div>
              </div>
            </div>
            <div className='w-col w-col-4'>
              <div
                className='feature-block'
                style={{
                  opacity: 1,
                  transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d'
                }}
              >
                <img
                  src={idealFraming3ImageUrl} loading='lazy' width='300' alt='' className='process-image'
                />
                <div className='flex-vertical space-top-large'><h4 className='no-space-bottom'>Ideal framing</h4>
                  <div className='opacity-dark-text'>The full court is in the frame and the camera is high enough off
                    the ground.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-row'>
            <div className='w-col w-col-4'>
              <div
                className='feature-block'
                style={{
                  opacity: 1,
                  transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d'
                }}
              >
                <img
                  src={notGreatFramingImageUrl} loading='lazy' width='300' alt='' className='process-image'
                />
                <div className='flex-vertical space-top-large'><h4 className='no-space-bottom'>Not great</h4>
                  <div className='opacity-dark-text'>Too much of the court is cut out of the frame. The height of the
                    camera is adequate<span className='text-span-2' />
                  </div>
                </div>
              </div>
            </div>
            <div className='w-col w-col-4'>
              <div
                className='feature-block'
                style={{
                  opacity: 1,
                  transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d'
                }}
              >
                <img
                  src={notGreatFraming2ImageUrl} loading='lazy' width='300' alt='' className='process-image'
                />
                <div className='flex-vertical space-top-large'><h4 className='no-space-bottom'>Not great</h4>
                  <div className='opacity-dark-text'>Too much of the court is cut out of the frame. Plus, the height of
                    the camera is too low
                  </div>
                </div>
              </div>
            </div>
            <div className='w-col w-col-4'>
              <div
                className='feature-block'
                style={{
                  opacity: 1,
                  transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d'
                }}
              >
                <img src={notGreatFraming3ImageUrl} loading='lazy' width='300' alt='' className='process-image' />
                <div className='flex-vertical space-top-large'><h4 className='no-space-bottom'>Not great</h4>
                  <div className='opacity-dark-text'>Too many courts/players in the frame can confuse the system. Try to
                    move closer and crop out the others.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='container center w-container'>
          <h2 className='heading-4'>A steady hand is not enough</h2>
          <p className='center-text medium-text subead opacity-dark-text'>
            A tripod or a solid base is a must. The important thing is that your phone or camera of choice does not move while the video is being recorded.
          </p>
          <div className='split'>
            <div
              className='feature-block'
              style={{
                opacity: 1,
                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                transformStyle: 'preserve-3d',
                gridArea: 'span 1 / span 1 / span 1 / span 1'
              }}
            >
              <div className='center-content center-text'>
                <img src={steadyHandGreenImageUrl} loading='lazy' width='300' alt='' className='process-image' />
                <div className='process-number green'>
                  <div className='text-block-3'>✓</div>
                </div>
              </div>
            </div>
            <div
              className='feature-block'
              style={{
                opacity: 1,
                transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                transformStyle: 'preserve-3d',
                gridArea: 'span 1 / span 1 / span 1 / span 1'
              }}
            >
              <div className='center-content center-text'>
                <img src={steadyHandRedImageUrl} loading='lazy' width='300' alt='' className='process-image' />
                <div className='process-number red'>
                  <div className='text-block-4'>X</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='uploads' className='section'>
        <div className='container center w-container'>
          <h2 className='heading-4'>Upload just one game per video</h2>
          <p className='medium-text limit-large'>
            Video uploads are restricted to <strong>30 minutes in length</strong>. If you recorded a longer
            video with multiple games, try cutting out each game as individual videos and upload those.
          </p>
          <h2 className='heading-9'>Singles is not yet supported.. sorry 😔</h2>
          <p className='medium-text limit-large'>
            If support for singles games is a priority for you, please give it a vote over on&nbsp;
            <a href='https://roadmap.pb.vision/' target='_blank' rel='noreferrer'>our roadmap</a>
          </p>
        </div>
      </div>
      <div className='section grey'>
        <div className='container medium w-container'><h2 className='center-text'>Frequently asked questions</h2>
          <div className='split less-space space-top'>
            <div className='faq-wrapper'>
              <FaqAccordion
                question={<span>Do I&nbsp;have to use a smart phone?</span>} answer={
                  <span>
                    Nope! GoPro,phone,DSLR. Any digital video camera that can record at
                    more than 24 frames per second should be just fine.
                  </span>
              }
              />
              <FaqAccordion
                question='Are singles games supported?' answer={
                  <span>
                    Not yet. We&apos;re
                    actively working on supporting singles games, but we can&apos;t quite do it yet. There needs to be 4
                    players in your video for it to be processed without error.
                  </span>
              }
              />
              <FaqAccordion
                question='What color ball works best?' answer={
                  <span>
                    Any color! We&apos;ve not had any issues tracking balls of various colors.
                    If it seems like your video is having an issue, please get in touch at&nbsp;
                    <a href='mailto:help@pb.vision?subject=bug'>help@pb.vision </a>
                    or on&nbsp;
                    <a href='https://discord.com/invite/N73jSVDpwK' target='_blank' rel='noreferrer'>Discord</a>
                  </span>
                }
              />
              <FaqAccordion
                question={<span>What FPS and resolution should I&nbsp;use?</span>} answer={
                  <span>
                    Our AI works equally well on any video 720p 30fps and up. You can upload whatever resolution and
                    frame-rate you like best, just keep in mind that there is a 10gb max file size.
                  </span>
              }
              />
            </div>
            <div className='faq-wrapper'>
              <FaqAccordion
                question='Is there a size limit on the video files?' answer={
                  <span>
                    Yes, the size limit is 10gb. If your video file size is too large, consider using
                    your OS tools for reducing resolution or trimming un-needed footage.
                  </span>
              }
              />
              <FaqAccordion
                question='What angles work?' answer={
                  <span>
                    360 degrees! As long as all 4 corners of the court are in the frame and the camera
                    is high enough off the ground, your video should process successfully.
                  </span>
              }
              />
              <FaqAccordion
                question='Is there a way to upload multiple videos for one game?' answer={
                  <span>
                    No, a game must be uploaded as a single video file for now - you would need to combine or stich multiple
                    clips into a single game and ensure the resulting file is less than the size limit of 10gb.
                  </span>
              }
              />
              <FaqAccordion
                question='How long does it take to process a video?' answer={
                  <span>
                    It depends on our server load and the number of videos in the queue
                    at any given time, but you should expect at least a few hours.
                  </span>
              }
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default VideoTipsPage
