import styled from '@emotion/styled'
import clsx from 'clsx'
import React, { forwardRef, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { SimpleButton } from '../button/simple-button'

import GreenCheckIcon from '@/assets/icons/signs/check-rounded.svg?react'
import AlertIcon from '@/assets/icons/signs/exclamation-rounded.svg?react'
import InfoIcon from '@/assets/icons/signs/exclamation-triangle.svg?react'
import ExitIcon from '@/assets/icons/signs/x-clean.svg?react'
import useMobileDetect from '@/hooks/use-mobile-detect'
import COLORS from '@/utils/colors'
import { row } from '@/utils/flexGrid'

/**
 * Represents an alert banner with a dynamic icon and style based on the type of alert.
 *
 * @param {object} props - Props for the alert banner component.
 * @param {string} props.id - Unique identifier for the alert banner.
 * @param {string} props.title - The title of the alert banner.
 * @param {string} props.permanent - If true, the alert banner will not be dismissed by the user
 * @param {string} props.dismissibleOnMobile - If true, the alert banner will be dismissible by the user on mobile devices
 * @param {string} props.message - The descriptive message to be displayed on the alert.
 * @param {string} [props.type] - The type of the alert ('info', 'warning', or 'success').
 * @param {boolean} [props.autoDismiss] - If true, the alert banner will dismiss automatically when the page changes.
 * @param {Function} props.onRemove - Function to call to remove the alert from display.
 * @param {string} props.ref - Ref to the container element
 * @returns {React.ReactElement} The JSX element of the alert banner.
 */
// eslint-disable-next-line react/display-name
export const AlertBanner = forwardRef((props, ref) => {
  const isMobile = useMobileDetect()
  const location = useLocation()
  const [path, setPath] = React.useState(location.pathname)

  const dismissibleOnMobile = props.dismissibleOnMobile && isMobile

  const Icon = useMemo(() => {
    if (props.type === 'success') return GreenCheckIcon
    if (props.type === 'warning') return AlertIcon
    if (props.type === 'info') return InfoIcon

    return InfoIcon
  }, [props.type])

  // Sets up auto-dismissal on path change if `autoDismiss` is true
  useEffect(() => {
    if (props.autoDismiss) {
      if (path !== location.pathname) {
        setPath(location.pathname)
        props.onRemove(props.id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <Container className={isMobile && 'mobile'} ref={ref}>
      <div className={clsx('content', props.type)}>
        <Icon className='sign' />
        <div className='message'>
          <strong>{props.title}</strong>
          <p>{props.message}</p>
        </div>
        <div className='divider' />
        {/* <div className="checkbox">
          <FormControlLabel control={<Checkbox />} label="Don't show again" />
        </div> */}
        {(!props.permanent || dismissibleOnMobile) &&
          <SimpleButton className='close' onClick={() => props.onRemove(props.id)}>
            <ExitIcon />
          </SimpleButton>}
      </div>
    </Container>
  )
})

const Container = styled('div')({
  width: '100% !important',
  padding: '8px 8px 4px 8px !important',
  '& .content': {
    alignItems: 'center',
    padding: 16,
    borderRadius: '12px',
    border: `1px solid ${COLORS['warning-200']}`,
    background: COLORS['warning-050'],
    fontSize: 14,
    ...row,

    '& .divider': {
      marginLeft: 'auto'
    },

    '& .sign': {
      width: 24,
      marginRight: 16,
      path: {
        fill: COLORS['warning-500']
      }
    },
    '& .message': {
      flex: 1,
      maxWidth: '70%',
      marginRight: 16,
      color: COLORS['warning-800'],
      '& strong': {
        lineHeight: 0,
        fontSize: 16
      },
      '& p': {
        fontWeight: 500,
        margin: 0
      }
    },
    '& .checkbox': {
      '& span': {
        textWrap: 'nowrap',
        color: COLORS['warning-800'],
        fontSize: 14
      },
      '& path': {
        backgroundColor: 'white'
      }
    },
    '& .close': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 8,
      border: `1px solid ${COLORS['warning-200']}`,
      borderRadius: 8,
      cursor: 'pointer',
      '& svg path': {
        fill: COLORS['warning-800']
      },
      '&:hover': {
        backgroundColor: `${COLORS['neutral-200']}70`,
        transition: 'background-color 0.3s'
      }
    },

    // styles based on props.type
    '&.warning': {
      borderColor: COLORS['danger-200'],
      background: COLORS['danger-050'],
      '& .sign path': {
        fill: COLORS['danger-500']
      },
      '& .message': {
        color: COLORS['danger-500']
      },
      '& .checkbox': {
        color: COLORS['danger-500'],
        '& .MuiCheckbox-root': {
          color: COLORS['danger-500']
        }
      },
      '& .close': {
        borderColor: COLORS['danger-200'],
        '& svg path': {
          fill: COLORS['danger-500']
        }
      }
    },
    '&.success': {
      borderColor: COLORS['success-300'],
      background: COLORS['success-050'],
      '& .sign path': {
        fill: COLORS['success-800']
      },
      '& .message': {
        color: COLORS['success-800']
      },
      '& .checkbox': {
        color: COLORS['success-800'],
        '& .MuiCheckbox-root': {
          color: COLORS['success-800']
        }
      },
      '& .close': {
        borderColor: COLORS['success-300'],
        '& svg path': {
          fill: COLORS['success-800']
        }
      }
    }
  },
  // responsive (mobile)
  '&.mobile': {
    '& .content': {
      alignItems: 'flex-start',
      '& .close': {
        border: 'none'
      }
    }
  }
})
