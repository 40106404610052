import { useEffect, useState } from 'react'

import { isInMobileAppWebview, notifySupportRequested } from '@/utils/mobile-app-communication'

export default function useZendesk () {
  const [isOpen, setIsOpen] = useState(false)

  /**
   * usage of window.zE('webWidget', 'hide') prevent displaying default ZenDesk chat bubble.
   */

  function onScriptLoaded () {
    // Set up Zendesk widget settings
    if (window.zE) {
      window.zE('webWidget', 'hide') // initially prevent displaying bubble

      window.zESettings = {
        webWidget: {
          position: { horizontal: 'right', vertical: 'bottom' },
          zIndex: 10000
        }
      }

      // Subscribe to Zendesk onCLOSE widget events
      window.zE('webWidget:on', 'close', function () {
        // This function will be called when the Zendesk widget is closed by their natively event (click on -)
        window.zE('webWidget', 'hide')
        setIsOpen(false)
      })
    }
  }

  useEffect(() => {
    // Check if the script is already included
    const existingScript = document.getElementById('ze-snippet')
    if (existingScript) {
      // If it exists, call the onScriptLoaded function directly
      onScriptLoaded()
    } else if (!isInMobileAppWebview()) {
      const script = document.createElement('script')
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${import.meta.env.VITE_ZENDESK_KEY}`
      script.defer = true
      script.id = 'ze-snippet'
      script.addEventListener('load', onScriptLoaded)
      document.body.appendChild(script)
    }
  }, [])

  function toggleZendeskWidget () {
    // Open email client if in mobile app webview
    // This is a workaround for the issue where the Zendesk widget is not working in the mobile app webview
    if (isInMobileAppWebview()) {
      notifySupportRequested()
      return
    }
    // Toggle Zendesk widget visibility
    if (window.zE) {
      if (isOpen) {
        window.zE('webWidget', 'hide')
      } else {
        window.zE('webWidget', 'show')
        window.zE('webWidget', 'open')
      }
      setIsOpen((state) => !state)
    }
  }

  return { toggleZendeskWidget }
}
