import DeleteIcon from '@mui/icons-material/Delete'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove'
import EditIcon from '@mui/icons-material/Edit'
import LinkIcon from '@mui/icons-material/Link'
import MapIcon from '@mui/icons-material/Map'
import TodayIcon from '@mui/icons-material/Today'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import ContextMenu from '@/components/context-menu'
import { PageContext } from '@/pages/library/context'
import { useIsLoggedIn } from '@/store/auth'
import { removeVideoFromLibrary } from '@/store/library'
import { useDialog } from '@/store/providers/dialog-provider'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import { isProd } from '@/utils'
import COLORS from '@/utils/colors'
import { copyToClipboard } from '@/utils/helper'
import { isInMobileAppWebview } from '@/utils/mobile-app-communication'

/**
 * VideoContextMenu component
 *
 * @param {object} props - VideoContextMenu props
 * @param {object} props.className - className
 * @returns {React.ReactElement} VideoContextMenu component
 */
function VideoContextMenu ({ className, videoExcerpt }) {
  const { setIsMoveItemsOpen, setItemsForMoving, bulkSelected } = useContext(PageContext)
  const dispatch = useDispatch()
  const { vid } = videoExcerpt
  const openSnackbar = useSnackbar()
  const isLoggedIn = useIsLoggedIn()
  const showDialog = useDialog()
  const navigate = useNavigate()

  const openShotExplorer = () => {
    navigate(`/video/${vid}/explore`)
  }

  const onCopyLink = async () => {
    const serverUrl = isProd ? 'https://share.pb.vision' : import.meta.env.VITE_API_SERVER
    const shareURL = `${serverUrl}/video/share/${vid}`
    copyToClipboard(shareURL)
      .then(() => {
        openSnackbar('Link copied to clipboard')
      })
      .catch(() => {
        openSnackbar('Failed to copy то clipboard', 'error')
      })
  }

  const onEditRallies = () => {
    console.log(`TODO: need to hook up onEditRallies: ${videoExcerpt.name}`)
  }

  const onEditDate = () => {
    console.log(`TODO: need to hook up onEditDate: ${videoExcerpt.name}`)
  }

  const onRemoveFromLibrary = async () => {
    showDialog({
      title: 'Are you sure?',
      description: 'This action cannot be undone',
      disagreeText: 'Remove from Library',
      agreeText: 'Keep in library',
      onCancel: () => {
        dispatch(removeVideoFromLibrary(vid))
      }
    })
  }

  const onMoveVideoToFolder = () => {
    setIsMoveItemsOpen(true)

    console.log(bulkSelected)

    if (bulkSelected?.length) {
      setItemsForMoving(bulkSelected)
    } else {
      setItemsForMoving(state => [...state, videoExcerpt])
    }
  }

  const items = [
    {
      icon: <EditIcon />,
      text: 'Edit Rallies',
      onClick: onEditRallies,
      hidden: true
    },
    {
      icon: <TodayIcon />,
      text: 'Edit Date',
      onClick: onEditDate,
      hidden: true
    },
    {
      icon: <LinkIcon sx={{ color: COLORS['primary-500'] }} />,
      text: 'Copy Link',
      onClick: onCopyLink
    },
    {
      icon: <MapIcon />,
      text: 'Shot Explorer',
      onClick: openShotExplorer
    },
    {
      icon: <DeleteIcon />,
      text: 'Remove from Library',
      onClick: onRemoveFromLibrary,
      hidden: !isLoggedIn && isInMobileAppWebview()
    },
    {
      icon: <DriveFileMoveIcon />,
      text: 'Move',
      onClick: onMoveVideoToFolder
    }
  ]

  return <ContextMenu items={items} className={className} />
}

export default VideoContextMenu
