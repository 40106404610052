/**
 * @file ShareFolderDialog.js
 * @description A dialog component for sharing a folder.
*/

import CloseIcon from '@mui/icons-material/Close'
import LinkIcon from '@mui/icons-material/Link'
import { Checkbox, FormControlLabel, styled } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { forwardRef, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import PublicFolderIcon from '@/assets/icons/public-folder-icon.svg?react'
import ShareFolderIcon from '@/assets/icons/share-icon.svg?react'
import { Button } from '@/components/button'
import { Toggle } from '@/components/toggle'
import useKeypress from '@/hooks/use-keypress'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useLoggedInUserCredentials } from '@/store/auth'
import { updateFolder } from '@/store/library'
import { useSnackbar } from '@/store/providers/snackbar-provider'
import { isProd } from '@/utils'
import COLORS from '@/utils/colors'
import { copyToClipboard } from '@/utils/helper'
import { isInMobileAppWebview, notifyShareRequested } from '@/utils/mobile-app-communication'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

/**
 * ShareFolderDialog component for sharing a folder.
 * @function
 * @param {object} props
 * @param {boolean} props.folder - Represent folder for changing public status.
 * @param {function} props.setIsOpen - Function to set the dialog open state.
 * @returns {JSX.Element} The ShareFolderDialog component.
 */

export default function ShareFolderDialog (props) {
  const isMobile = useMobileDetect()
  const openSnackbar = useSnackbar()
  const dispatch = useDispatch()
  const { fid } = useParams()
  const { uid } = useLoggedInUserCredentials()

  const [isPublic, setIsPublic] = useState(false)
  const [isInitiallyPublic, setIsInitiallyPublic] = useState(false)
  const [doubleCheck, setIsDoubleChecked] = useState(true)
  const [isDifferent, setIsDifferent] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const libraryOrFolderLabel = props?.folder?.root ? 'Entire Library' : 'Folder'

  const handleClose = () => {
    props.setIsOpen(false)
    setIsDoubleChecked(true)
  }

  const handleCopyLink = async () => {
    let shareURL = ''
    shareURL = isProd ? 'https://pb.vision' : 'https://pbv-dev.web.app'
    shareURL += `/library/public/${uid}`
    if (fid) {
      shareURL += `/${fid}`
    }

    if (isInMobileAppWebview()) {
      notifyShareRequested(shareURL)
    } else {
      copyToClipboard(shareURL)
        .then(() => {
          openSnackbar('Link copied to clipboard')
        })
        .catch(() => {
          openSnackbar('Failed to copy то clipboard', 'error')
        })
    }
  }

  const handleAccept = async () => {
    let objForUpdate = {}

    if (props.folder.root) {
      objForUpdate = {
        public: isPublic
      }
    } else {
      objForUpdate = {
        public: isPublic,
        fid: props.folder.fid
      }
    }

    try {
      setIsLoading(true)
      await dispatch(updateFolder(objForUpdate))
      isInitiallyPublic
        ? openSnackbar(`${libraryOrFolderLabel} successfully updated.`)
        : handleCopyLink()
    } catch (error) {
      openSnackbar('An error occurred while updating the folder. Please try again.', 'error')
    } finally {
      handleClose()
      setIsLoading(false)
    }
  }

  function onChangePublicStatus (status) {
    setIsPublic(status)
  }

  useKeypress('Enter', () => {
    isDifferent && handleAccept()
  })

  useEffect(() => {
    if (props?.folder?.root) {
      !props?.folder?.root.public && setIsDoubleChecked(false)
      setIsPublic(Boolean(props?.folder?.root.public))
      setIsInitiallyPublic(Boolean(props?.folder?.root.public))
      setIsDifferent(props?.folder?.root.public !== isPublic)
    } else {
      setIsPublic(Boolean(props.folder?.public))
      setIsInitiallyPublic(Boolean(props.folder?.public))
      setIsDifferent(Boolean(props.folder.public) !== isPublic)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.folder])

  useEffect(() => {
    if (props?.folder?.root) {
      setIsDifferent(Boolean(props?.folder?.root.public) !== isPublic)
    } else {
      setIsDifferent(Boolean(props.folder.public) !== isPublic)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublic])

  return (
    <>
      <StyledDialog
        open={Boolean(props?.folder)}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <div className={`content ${isMobile && 'mobile'}`}>
          <div className='head'>
            <p>{`Share "${props.folder.name || 'Entire Library'}"`}</p>
            <CloseIcon onClick={handleClose} />
          </div>
          <div className='toggle-container'>
            {isPublic ? <PublicFolderIcon /> : <ShareFolderIcon />}
            <div className='description'>
              <strong className='title'>{libraryOrFolderLabel} {isPublic ? ' is public' : ' is Private '}</strong>
              <strong className='subtitle'>{isPublic ? 'Can be viewed by anyone with the link' : 'Can only be viewed by owner'} </strong>
            </div>
            <Toggle tooltip={isPublic ? 'Unshare' : 'Share'} onChange={(checked) => onChangePublicStatus(checked)} checked={isPublic} />
          </div>

          {isPublic && props?.folder?.root && isDifferent &&
            <FormControlLabel
              classes={{ root: 'formControl', label: 'label' }}
              control={
                <Checkbox
                  className='checkbox'
                  color='primary'
                  checked={doubleCheck}
                  onChange={(evt) => {
                    setIsDoubleChecked(evt.target.checked)
                  }}
                />
          }
              label='I understand that all videos and folders will be public'
            />}

          <div className='bottom-part'>
            {isInitiallyPublic && isPublic &&
              <Button className='gray copy-link' onClick={handleCopyLink}>Copy link <LinkIcon /></Button>}
            <div className='actions'>
              <Button
                className={(isDifferent && doubleCheck) ? 'green' : 'gray-filled'} disabled={!isDifferent || isLoading || !doubleCheck} onClick={handleAccept}
              >{isInitiallyPublic ? 'Save' : 'Save and Copy Link'}
              </Button>
            </div>
          </div>
        </div>
      </StyledDialog>
    </>
  )
}

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    backgroundColor: COLORS['neutral-050'],
    border: `1px solid ${COLORS['neutral-300']}`,
    margin: 0,
    maxWidth: 'unset'
  },
  '& .content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '16px 24px',
    width: '600px',

    '& .formControl': {
      alignSelf: 'flex-end',
      flexDirection: 'row-reverse',
      marginRight: '-10px !important'
    },

    '& .toggle-container': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      border: `1px solid ${COLORS['neutral-300']}`,
      padding: '8px 16px',

      '& .description': {
        display: 'flex',
        flexDirection: 'column',
        '& .subtitle': {
          fontSize: '12px'
        }
      },
      '& label': {
        marginLeft: 'auto'
      }
    },
    '& .head': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& svg': {
        cursor: 'pointer'
      },
      '& p': {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: COLORS['neutral-800']
      }
    },
    '& input': {
      padding: '8px'
    },
    '& .bottom-part': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .copy-link svg': {
        transform: 'rotateZ(315deg)'
      },
      '& .actions': {
        display: 'flex',
        marginLeft: 'auto',
        gap: '8px',
        '& button': {
          maxHeight: '32px'
        }
      }
    },
    '&.mobile': {
      width: '90vw'
    }
  }

}))
