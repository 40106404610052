import { styled } from '@mui/material'
import React, { useContext } from 'react'

import { PlayerStatsContext } from '..'

import CourtServesReturns from '@/assets/court-serves-returns.svg?react'
import HelpIcon from '@/assets/help.svg?react'
import { ServesReturns } from '@/components/serves-and-returns'
import { Tooltip } from '@/components/tooltip'
import useMobileDetect from '@/hooks/use-mobile-detect'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

export default function ServesReturnGraph (props) {
  const context = useContext(PlayerStatsContext)
  const isMobile = useMobileDetect()
  const playerIdx = props.playerIdx

  const insights = context?.insights

  if (!insights) {
    return null
  }

  const playerName = props.player.name ? `${props.player.name}'s` : `Player ${playerIdx + 1}`

  return (
    <Container className={isMobile && 'mobile'}>
      <div className='graph-description'>
        <div className='desc-title'>
          <Tooltip
            title={`This section shows the depth of ${playerName} serves and returns during this game.`}
          >
            <HelpIcon />
          </Tooltip>
          <strong>
            Serves & Returns
          </strong>
        </div>
        <span className='desc-subtitle'>
          Depth
        </span>
        <CourtServesReturns className='serves-return-court' />
      </div>
      <div className='graph'>
        <ServesReturns insights={insights} playerIdx={playerIdx} />
      </div>
    </Container>
  )
}

const Container = styled('div')({
  ...row,
  padding: 32,
  '& .graph-description': {
    ...column,
    width: 200,
    minWidth: 200,
    color: COLORS.black,
    alignItems: 'flex-end',
    gap: 8,
    '& .desc-title': {
      display: 'flex',
      gap: 10,
      '& strong': {
        fontSize: '20px',
        lineHeight: '28px'
      }
    },
    '& .desc-subtitle': {
      fontSize: 12,
      color: COLORS['neutral-500']
    }
  },
  '& .graph': {
    display: 'flex',
    flexGrow: 1
  },
  '&.mobile': {
    ...column,
    padding: 15,
    gap: 20,
    '& .graph-description': {
      width: '70%',
      marginLeft: 5,
      alignItems: 'flex-start',
      '& .desc-subtitle': {
        marginLeft: 'auto'
      }
    },
    '& .serves-return-court': {
      width: '100%'
    },
    '& .graph .mobile': {
      width: '100%'
    }
  }
})
