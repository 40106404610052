import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { CreateAccountMessage } from '@/components/create-account-banner/create-account-msg'
import { useShowCreateAccountBanner, showCreateAccountBanner } from '@/store/anonymous'
import { useIsLoggedIn } from '@/store/auth'
import { useAlertBanner } from '@/store/providers/alert-banner-provider'
import { uploadFormatErrored, useUploadFormatErrored } from '@/store/uploads'

export function useAccountBanner () {
  const showBanner = useShowCreateAccountBanner()
  const { showAlert } = useAlertBanner()
  const dispatch = useDispatch()
  const isLoggedIn = useIsLoggedIn()
  const uploadFormatError = useUploadFormatErrored()

  useEffect(() => {
    if (showBanner) {
      if (isLoggedIn) {
        showAlert({
          type: 'warning',
          dismissibleOnMobile: true,
          title: 'Not Your Video',
          message: 'Only the person who uploaded this video may edit it.',
          isAnimated: true
        })
      } else {
        showAlert({
          type: 'success',
          dismissibleOnMobile: true,
          title: 'Please create an account to unlock this feature.',
          message: <CreateAccountMessage />,
          isAnimated: true
        })
      }
      dispatch(showCreateAccountBanner(false))
    } else if (uploadFormatError) {
      showAlert({
        type: 'warning',
        dismissibleOnMobile: true,
        title: 'Unsupported Video',
        message: 'This browser does not support the requested video format. Please try a different browser. For additional help, please click the help icon in the top right corner of this page.',
        isAnimated: true
      })
      dispatch(uploadFormatErrored(false))
    }
  }, [dispatch, isLoggedIn, showAlert, showBanner, uploadFormatError])
}
