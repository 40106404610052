import { styled } from '@mui/material/styles'
import Plot from 'react-plotly.js'

import { useNthShotsForPlayerWithContext } from '../player-stats/stats-sections/selected-shots-viewer'

import useMobileDetect from '@/hooks/use-mobile-detect'
import cl from '@/utils/classnames'
import { round } from '@/utils/helper'

const serveTypes = {
  Deep: '#7FFF77',
  Medium: '#FFE074',
  Shallow: '#FF7790',
  Net: '#F8B3FF'
}
const labels = Object.keys(serveTypes)
const colors = Object.values(serveTypes)

// cspell:disable
const genericAnnotation = {
  font: {
    size: 16,
    family: 'Inter, Helvetica, Arial, sans-serif'
  },
  showarrow: false,
  x: 0.5,
  y: 0.5
}

const genericLayout = {
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 0,
    pad: 0
  },
  font: {
    // size: 16,
    weight: 500,
    family: 'Inter, Helvetica, Arial, sans-serif'
  },
  showlegend: false,
  paper_bgcolor: '#f0f4f8'
}

const genericData = {
  labels,
  marker: {
    colors
  },
  textposition: 'inside',
  texttemplate: '%{percent:.2p}',
  hoverinfo: 'none',
  type: 'pie'
}
// cspell:enable

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,

  '& ._pies': {
    display: 'flex',
    gap: 40,
    paddingLeft: 60,

    // Center vertically smaller (second) pie
    '& > div:last-of-type': {
      alignSelf: 'center'
    }
  },

  '& ._legend': {
    display: 'flex',
    color: '#000',
    gap: 31,
    justifyContent: 'center',
    marginTop: 'auto',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12
    },
    ...labels.reduce((acc, label) => {
      acc[`& .${label}:before`] = {
        content: '""',
        display: 'block',
        backgroundColor: serveTypes[label],
        width: 23,
        height: 12,
        marginRight: 6,
        borderRadius: 2,
        border: '1px solid rgba(0, 0, 0, 0.12)'
      }
      return acc
    }, {})
  },
  // Force bold text on slice labels
  '& .main-svg g.slice text': {
    fontWeight: 600
  },
  // Mobile overrides
  '&.mobile': {
    '& ._pies': {
      flexDirection: 'column',
      gap: 20,
      paddingLeft: 0,
      alignItems: 'center',
      marginTop: 20
    },
    '& ._legend': {
      marginTop: 20,
      flexWrap: 'wrap',
      gap: 0,
      ...labels.reduce((acc, label, index) => {
        acc[`& > div.${label}`] = {
          width: (index % 2 !== 0) ? '40%' : '60%',
          paddingLeft: (index % 2 !== 0) ? '0' : '23%'
        }
        return acc
      }, {})
    }
  }
})

function Pie (props) {
  const { values, name, hole, width, height } = props

  return (
    <Plot
      layout={{
        ...genericLayout,
        annotations: [{ ...genericAnnotation, text: `<b>${name.replace(' ', '<br>')}</b>` }],
        width,
        height
      }}
      data={[{ ...genericData, values, name, hole }]}
      config={{ displayModeBar: false, responsive: true }}
    />
  )
}

const typesToArray = (object) => {
  const { deep = 0, mid = 0, short = 0, net = 0 } = object
  return [round(deep), round(mid), round(short), round(net)]
}

function useDepthsForShots (insights, playerIdx, shotNum) {
  const shotTypesToInclude = new Set(['drive', 'drop', 'lob'])
  const ret = { deep: 0, mid: 0, short: 0, net: 0 }
  const shots = useNthShotsForPlayerWithContext(insights, playerIdx, shotTypesToInclude, shotNum)
  shots.forEach(obj => {
    const { shot } = obj
    const endZone = shot.resulting_ball_movement?.trajectory?.end?.zone
    if (endZone) {
      const zoneToUpdate =
        Object.prototype.hasOwnProperty.call(ret, endZone)
          ? endZone
          : (endZone === 'kitchen' ? 'short' : undefined)
      if (zoneToUpdate) {
        ret[zoneToUpdate]++
      }
    }
  })
  return ret
}

/**
 *
 * @param props
 */
export function ServesReturns (props) {
  const isMobile = useMobileDetect()
  const { playerIdx, insights } = props
  const serves = useDepthsForShots(insights, playerIdx, 1)
  const returns = useDepthsForShots(insights, playerIdx, 2)
  return (
    <Container className={cl(isMobile && 'mobile')}>
      <div className='_pies'>
        <Pie values={typesToArray(serves)} hole={0.6} width={230} height={230} name='Serve Depth' />
        <Pie values={typesToArray(returns)} hole={0.6} width={180} height={180} name='Return Depth' />
      </div>
      <div className='_legend'>
        {labels.map((label) => (
          <div key={label} className={label}>
            {label}
          </div>
        ))}
      </div>
    </Container>
  )
}
