/* eslint-disable react/no-array-index-key */
/// <reference types="vite-plugin-svgr/client" />
import { styled } from '@mui/material/styles'
import { createContext, Fragment, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useRenamePlayer } from '../overview'

import CourtCoverageGraph from './stats-sections/court-coverage-graph'
import SelectedShotsViewer from './stats-sections/selected-shots-viewer'
import ServesReturnGraph from './stats-sections/serves-return-graph'
import StatsHeadInfo from './stats-sections/stats-head-info'
import StickyHeader from './stats-sections/sticky-header'

import useMobileDetect from '@/hooks/use-mobile-detect'
import { useStickyScroll } from '@/hooks/use-sticky'
import { useURLParams } from '@/hooks/use-url-params'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

/**
 * PlayerStats container
 *
 * @returns {React.ReactElement}
 */
const Container = styled('div')({
  ...column,
  marginTop: -32,
  '& .row': {
    ...row
  },
  '& .column': {
    ...column
  },
  '& .player1': {
    color: COLORS.player1
  },
  '& .player2': {
    color: COLORS.player2
  },
  '& .player3': {
    color: COLORS.player3
  },
  '& .player4': {
    color: COLORS.player4
  }

})

const initial3rdShotsState = Array.from({ length: 4 }, () => ({
  aspect: 660 / 44, // width = 660, range = 44
  aspectZ: 660 / 44, // width = 660, range = 44
  aspectCourt: (342 - 2 * 18) / 22, // width = 342, range = 22, 18px net width on each side
  aspectCourtY: 660 / 44, // height = 660, range = 44
  showLeftSide: true,
  showRightSide: true,
  normalize: true
}))

export const PlayerStatsContext = createContext(null)

export function PlayerStats ({ insights, video, workflow }) {
  const { vid, playerId } = useParams()
  const { nestParamsIntoURL } = useURLParams()
  const isMobile = useMobileDetect()
  const [shotNumberSelection, setShotNumberSelection] = useState(3)

  // eslint-disable-next-line no-unused-vars
  const state = initial3rdShotsState

  const { userData } = video

  const renamePlayerIfNeeded = useRenamePlayer()
  const onRenamePlayer = useCallback(
    (index) => (update) => { renamePlayerIfNeeded(video, index, update.text) },
    [renamePlayerIfNeeded, video]
  )

  const onShotNumberSelectionChange = useCallback(function onShotNumberSelectionChange (event) {
    event.preventDefault()
    setShotNumberSelection(parseInt(event.target.value))
  }, [])

  const [stickyIndex] = useStickyScroll(document.getElementById('pbvision'))

  useEffect(() => {
    const targetId = playerId

    if (targetId) {
      const sectionElement = document.querySelector(`.bookmark[data-bookmark="${targetId - 1}"]`)

      if (sectionElement) {
        sectionElement.scrollIntoView({ block: 'start' })
      }
    }
  }, [playerId])

  useEffect(() => {
    if (stickyIndex === null) {
      const newPath = `/video/${vid}/player`
      // eslint-disable-next-line no-restricted-globals
      if (window.location.pathname !== newPath) {
        /*
         *  Update URL without affecting the history stack (to prevent browser back button
         *  from going back to the previous player, or otherwise)
         */
        window.history.replaceState({}, '', nestParamsIntoURL(newPath))
      }
    } else {
      const newPath = `/video/${vid}/player/${stickyIndex + 1}`
      // eslint-disable-next-line no-restricted-globals
      if (window.location.pathname !== newPath) {
        window.history.replaceState({}, '', nestParamsIntoURL(newPath))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vid, playerId, stickyIndex])

  const players = [...userData.players]

  while (players.length < 4) {
    players.push({})
  }

  return (
    <PlayerStatsContext.Provider value={{ workflow, video, insights }}>
      <Container className={cls(isMobile && 'mobile')}>
        <StickyHeader onRenamePlayer={onRenamePlayer} video={video} />
        {players.map((player, playerIdx) => (
          <Fragment key={playerIdx}>
            <div className='bookmark' data-bookmark={playerIdx} />
            <StatsHeadInfo player={player} playerIdx={playerIdx} onRenamePlayer={onRenamePlayer} />
            <CourtCoverageGraph playerIdx={playerIdx} />
            <ServesReturnGraph playerIdx={playerIdx} player={player} />
            <SelectedShotsViewer insights={insights} playerIdx={playerIdx} shotNumberSelection={shotNumberSelection} onShotNumberSelectionChange={onShotNumberSelectionChange} />
          </Fragment>
        ))}
      </Container>
    </PlayerStatsContext.Provider>
  )
}
