import { Divider, styled } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import ArrowLeftIcon from '@/assets/arrow-left.svg?react'
import BugIcon from '@/assets/bug-icon.svg?react'
import emojiUrl from '@/assets/emoji-grimacing.png'
import { Button } from '@/components/button'
import useMobileDetect from '@/hooks/use-mobile-detect'
import useZendesk from '@/hooks/use-zendesk'
import { isDebugMode } from '@/utils'
import COLORS from '@/utils/colors'
import { row, column } from '@/utils/flexGrid'
export default function ProcessingFailed ({ video, workflow }) {
  const isMobile = useMobileDetect()
  const navigate = useNavigate()
  const { toggleZendeskWidget } = useZendesk()

  function onBackToLibraryHandler () {
    navigate('/library')
  }

  function onContactUsHandler () {
    toggleZendeskWidget()
  }

  const CheckPoints = () => {
    return (
      <div className='info-container'>
        <strong className='info-title'>To ensure successful processing, please check:</strong>
        <div className='info'>
          <div>
            <strong>• Game Type:</strong>
            <p>
              The video must be of a doubles pickleball game.
            </p>
          </div>
        </div>
        <div className='info'>
          <div>
            <strong>• Length:</strong>
            <p>
              The video should be no longer than 30 minutes.
            </p>
          </div>
        </div>
        <div className='info'>
          <div>
            <strong>• Format:</strong>
            <p>
              The video should be at least 720p and 30fps.
            </p>
          </div>
        </div>
        <div className='info'>
          <div>
            <strong>• Orientation:</strong>
            <p>
              The video should be in landscape mode.
            </p>
          </div>
        </div>
        <div className='info'>
          <div>
            <strong>• Framing:</strong>
            <p>
              The entire court should be clearly visible, and the camera should
              be at least 4 feet off the ground.
            </p>
          </div>
        </div>
        <div className='info'>
          <div>
            <strong>• Stability:</strong>
            <p>
              Avoid handheld video. Use a tripod for steady footage.
            </p>
          </div>
        </div>
      </div>
    )
  }

  const IfErrorStateExist = () => {
    if (video.src.duration / 60 > 60) {
      return (
        <div className='info-container'>
          <strong className='info-title'>Sorry, your video was more than an hour long. We don’t currently support videos of this length.</strong>
        </div>
      )
    }
    return (
      <div className='info-container'>
        <strong className='info-title'>Sorry, we were unable to process your video. This is probably due to a bug on our end.</strong>
        {isDebugMode && (
          <pre style={{ background: 'white' }}>{JSON.stringify(workflow.error, null, 4)}</pre>
        )}
        <p>Our team has been notified.</p>
        <p>Your experience is top priority – rest assured, we’re on it!</p>
        <div className='buttons'>
          <Button className='icon-left green' onClick={() => onBackToLibraryHandler()}>
            <ArrowLeftIcon /> Back to Library
          </Button>
          <Button className='gray' onClick={() => onContactUsHandler()}>
            Contact Us <BugIcon />
          </Button>
        </div>
      </div>
    )
  }

  return (
    <Container className={isMobile ? 'mobile' : ''}>
      <div className='heading'>
        <img src={emojiUrl} alt='emoji grimacing' />
        <strong className='title'>Oops! We hit a snag while processing your game</strong>
      </div>
      <Divider />
      {workflow?.error ? <IfErrorStateExist /> : <CheckPoints />}

      <div className='learn-more'>
        <Link to='/video-tips'>
          <Button variant='contained' color='primary'>
            Learn more
          </Button>
        </Link>
      </div>
    </Container>
  )
}

const Container = styled('div')({
  borderRadius: '12px',
  border: `1px solid ${COLORS['warning-500']}`,
  background: COLORS['warning-050'],
  color: COLORS['neutral-700'],
  fontSize: 14,
  gap: 16,
  marginTop: 16,
  padding: 32,
  ...column,
  '& .row': {
    alignItems: 'flex-end',
    ...row
  },
  ' .heading': {
    alignItems: 'center',
    gap: 10,
    ...row
  },
  '& img': {
    width: 25
  },
  '& hr': {
    width: '100%'
  },
  '& .buttons': {
    gap: 16,
    marginBlock: 8,

    ...row,
    '& .back': {
      color: COLORS.white,
      backgroundColor: COLORS['success-700']
    }
  },
  '& .title': {
    fontSize: 24
  },
  '& .info-container': {
    alignItems: 'flex-start',
    width: '100%',
    gap: 10,
    ...column,
    '& .info-title': {
      fontSize: 16
    }
  },
  '& .info': {
    gap: 8,
    flexShrink: 0,
    alignItems: 'flex-start',
    ...row,
    '& a': {
      color: COLORS['neutral-700']
    },
    '& div': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '10px',
      '& strong': {
        textWrap: 'nowrap'
      }
    },
    '& svg': {
      minWidth: 24,
      '&.bad path': {
        fill: COLORS.error
      },
      '&.good path': {
        fill: COLORS['success-700']
      }
    }
  },
  '& .learn-more': {
    width: '100%',
    marginTop: 16,
    alignItems: 'center',
    gap: 8,
    ...row,
    '& button': {
      background: COLORS.white,
      border: `1px solid ${COLORS['neutral-300']}`,
      '&:hover': {
        background: COLORS['neutral-050'],
        borderColor: COLORS['neutral-400']
      }
    }
  },

  // responsive
  '&.mobile': {
    padding: 16,
    marginInline: 8,
    '& .info': {
      '& div': {
        ...column
      }
    }
  }
})
