import { styled } from '@mui/material/styles'

import HelpIcon from '@/assets/help.svg?react'
import { Tooltip } from '@/components/tooltip'
import useMobileDetect from '@/hooks/use-mobile-detect'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

/**
 * Counter container
 *
 * @returns {React.ReactElement}
 */
const Container = styled('div')({
  ...row,
  paddingTop: 35,
  padding: '0 16px 40px',

  '& .column': {
    ...column
  },
  // Override column to row on mobile devices
  '&.mobile': {
    ...column,
    '& .column': {
      ...row,
      '& .title': {
        flexBasis: '60%',
        justifyContent: 'flex-end',
        marginRight: 16
      }
    }
  },
  '& > div.column': {
    alignItems: 'center',
    flexGrow: 1
  },
  '& .title': {
    ...row,
    color: COLORS['neutral-500'],
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '150%',
    marginBottom: 8,
    '& svg': {
      marginLeft: 4
    }
  },
  '& .value': {
    color: COLORS['00-on-surface-high-emphasis'],
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '155%'
  }
})

/**
 * Counter component, showing Total Rallies / Kitchen Rallies / Avg Shots per Rally / Longest Rally
 *
 * @exports
 * @param props {object} { totalRallies, kitchenRallies, avgShotsPerRally, longestRally }
 * @returns {React.ReactElement}
 */
export function Counter (props) {
  const { totalRallies, kitchenRallies, avgShotsPerRally, longestRally } = props
  const isMobile = useMobileDetect()

  return (
    <Container className={cls(isMobile && 'mobile')}>
      <div className='column'>
        <div className='title'>
          Total Rallies
          <Tooltip title='Total Rallies is the number of rallies in this video. A rally begins with a serve and lasts until the end of that point.'>
            <HelpIcon />
          </Tooltip>
        </div>
        <div className='value'>{totalRallies}</div>
      </div>
      <div className='column'>
        <div className='title'>
          Kitchen Rallies
          <Tooltip title='The number of rallies where all players made it within a few feet of the kitchen.'>
            <HelpIcon />
          </Tooltip>
        </div>
        <div className='value'>{kitchenRallies}</div>
      </div>
      <div className='column'>
        <div className='title'>
          Avg Shots per Rally
          <Tooltip title='Average number of shots in each rally.'>
            <HelpIcon />
          </Tooltip>
        </div>
        <div className='value'>{avgShotsPerRally}</div>
      </div>
      <div className='column'>
        <div className='title'>
          Longest Rally
          <Tooltip title="Number of shots in the game's longest rally.">
            <HelpIcon />
          </Tooltip>
        </div>
        <div className='value'>{longestRally} shots</div>
      </div>
    </Container>
  )
}
