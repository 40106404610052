import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LicenseInfo } from '@mui/x-license'
import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxStoreProvider } from 'react-redux'
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'

import App from './App'
import store from './store'
import theme from './utils/theme'

import { isProd } from '@/utils'

LicenseInfo.setLicenseKey('e8dce772edcca3875eea3317206acf09Tz05Mzk5MSxFPTE3NTIxODMzNjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

const root = createRoot(document.getElementById('root'))

Sentry.init({
  dsn: 'https://b84d7c8e8a9e205ad8ae8829ee5aa492@o4506498659254272.ingest.sentry.io/4506502411517952',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ],
  environment: isProd ? 'prod' : 'dev',
  release: `insights@${import.meta.env.VITE_RELEASE}`,
  enabled: import.meta.env.VITE_RELEASE !== undefined
})
Sentry.setUser({ ip_address: '{{auto}}' })

root.render(
  <ReduxStoreProvider store={store}>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  </ReduxStoreProvider>
)

window.addEventListener('vite:preloadError', event => {
  console.log('vite preload error detected: refreshing')
  event.preventDefault()
  window.location.reload()
})
