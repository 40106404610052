import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { useMemo } from 'react'

import { getShotLabel } from '../shot-label-generator'

import { SimpleButton } from '@/components/button/simple-button'
import { Tooltip } from '@/components/tooltip'
import cls from '@/utils/classnames'
import { getShotTrajectoryColorByValue } from '@/utils/stats'
import { formatMs } from '@/utils/time'

export default function Rallies (props) {
  const { list, selected, setSelected, playerAvatars, muxPlayerRef, isPlaying } = props

  const filteredRallies = useMemo(() => {
    return list.filter((rally) => !rally.isContext)
  }, [list])
  const play = (evt) => {
    evt.stopPropagation()
    muxPlayerRef?.current?.play()
  }

  return (
    <section className='rallies'>
      {filteredRallies.map((data, idx) => (
        <div key={idx}>
          {(idx === 0 || filteredRallies[idx - 1].rallyIdx !== data.rallyIdx) && (
            <div className='title'>Rally #{data.rallyIdx + 1}</div>
          )}
          <div
            className={cls(
              'shot',
              `player${data.shot.player_id + 1}`,
              selected && selected.rallyIdx === data.rallyIdx && selected.shotIdx === data.shotIdx && 'selected'
            )}
            onClick={(e) => setSelected({ rallyIdx: data.rallyIdx, shotIdx: data.shotIdx, ms: data.mStart, auto: false })}
          >
            {playerAvatars[data.shot.player_id]}
            {(!isPlaying && selected && selected.rallyIdx === data.rallyIdx && selected.shotIdx === data.shotIdx) && (
              <SimpleButton className='play-overlay' onClick={play}>
                <PlayArrowIcon />
              </SimpleButton>
            )}
            <div className='time'>{formatMs(data.mStart)}</div>
            <Tooltip className='quality' title={`${Math.round(data.shot.quality.overall * 100)}% shot quality`} component='div'>
              <div
                className='dot'
                style={{ backgroundColor: getShotTrajectoryColorByValue(data.shot.quality.overall) }}
              />
            </Tooltip>
            <div className='name'>{getShotLabel(data.shot)}</div>
          </div>
        </div>
      ))}
    </section>
  )
}
