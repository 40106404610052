import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

import COLORS from '@/utils/colors'

export const thumbPhoneWidth = '100%'
export const thumbTabletWidth = '48%'
export const thumbDesktopWidth = '356px'

export const Container = styled(Link)({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px 16px 12px 16px',
  width: '32%',
  aspectRatio: '1.3/1',
  border: `1px solid ${COLORS['neutral-200']}`,
  borderRadius: '6px',
  textDecoration: 'none',
  backgroundColor: COLORS['neutral-100'],
  '& .transparent-checkbox': {
    position: 'absolute',
    zIndex: 1100,
    top: 0,
    left: 0
  },
  '& .thumbnail-container': {
    height: '75%',
    width: '100%',
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '6px',
    '& .duration-container': {
      position: 'absolute',
      top: '10px',
      right: '10px',
      width: '38px',
      height: '16px',
      backgroundColor: 'rgba(240, 244, 248, 0.6)',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 4px',
      zIndex: 1000,
      '& .duration': {
        fontWeight: '500',
        fontSize: '12px',
        color: COLORS['neutral-800'],
        lineHeight: '16px'
      }
    },
    '& .red-dot-container': {
      position: 'absolute',
      top: '-7px',
      right: '-7px',
      width: '15px',
      height: '15px',
      backgroundColor: COLORS['neutral-100'],
      borderRadius: '100%',
      padding: '3px',
      '& .red-dot': {
        width: '100%',
        height: '100%',
        backgroundColor: 'red',
        borderRadius: '100%'
      }
    },
    '& .playback-icon-overlay': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&.processing': {
        background: 'rgb(205 215 225 / 80%)',
        '& p': {
          padding: 0,
          margin: 0,
          color: COLORS['warning-900'],
          fontSize: 12,
          fontWeight: 600,
          lineHeight: '17px',
          textAlign: 'center',
          '&:first-of-type': {
            fontSize: 16,
            lineHeight: '24px'
          }
        }
      },
      '& .playback-icon-container': {
        width: '48px',
        height: '48px',
        borderRadius: '24px',
        backgroundColor: 'black',
        opacity: '0.46',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .playback-icon': {
          color: COLORS.white,
          fontSize: '33px'
        }
      }
    }
  },
  '& .footer': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: '1',
    paddingTop: '5px',
    justifyContent: 'space-between',
    '& .footer-top': {
      position: 'relative',
      '& .name-section': {
        display: 'flex',
        paddingRight: '25px'
      },
      '& .static-name-section': {
        display: 'flex',
        width: 'fit-content',
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflow: 'hidden',
        height: '33px',
        borderRadius: '5px',
        '&:hover': {
          color: COLORS['background-tooltip'],
          backgroundColor: COLORS['primary-100'],
          '& .name': {
            color: COLORS['background-tooltip']
          },
          '& .edit-icon': {
            color: '#161a1c'
          }
        },
        '& .name': {
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '24px',
          color: COLORS['neutral-800'],
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        },
        '& .edit-icon': {
          fontSize: '18px',
          marginLeft: '10px',
          color: '#bdbdbd'
        }
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiInputBase-root': {
          height: '33px',
          backgroundColor: 'white',
          '& input': {
            fontWeight: '600',
            fontSize: '16px',
            paddingLeft: '9px',
            paddingBottom: '17px'
          },
          '& fieldset': {
            borderColor: '#13940c'
          }
        }
      },
      '& .menu-button': {
        position: 'absolute',
        right: '-13px',
        top: '-3px',
        left: 'unset',
        '& svg': {
          color: COLORS['neutral-500']
        }
      }
    },
    '& .footer-bottom': {
      position: 'relative',
      '& .date-section': {
        display: 'flex'
      },
      '& .static-date-section': {
        display: 'flex',
        width: 'fit-content',
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflow: 'hidden',
        // height: '28px',
        borderRadius: '5px',
        '& .date': {
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: '16px',
          color: COLORS['neutral-800']
        },
        '& span': {
          // time display
          marginLeft: '18px'
        },
        '&:hover': {
          color: COLORS['background-tooltip'],
          backgroundColor: COLORS['primary-100'],
          '& .date': {
            color: COLORS['background-tooltip']
          },
          '& .edit-icon': {
            color: '#161a1c'
          }
        },
        '& .edit-icon': {
          fontSize: '18px',
          marginLeft: '10px',
          color: '#bdbdbd'
        }
      },
      '& .MuiFormControl-root': {
        maxWidth: '205px',
        '& .MuiInputBase-root': {
          height: '28px',
          backgroundColor: 'white',
          '& input': {
            fontWeight: '400',
            fontSize: '14px',
            paddingLeft: '9px',
            paddingBottom: '17px'
          },
          '& fieldset': {
            borderColor: '#13940c'
          }
        }
      }
    },
    '& .completed': {
      color: COLORS['warning-600'],
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16px',
      marginLeft: 'auto'
    }
  },
  '&:hover': {
    cursor: 'pointer',
    '& .playback-icon-container': {
      opacity: '0.6 !important'
    },
    '& .transparent-checkbox': {
      display: 'flex'
    }
  },
  '&.selected': {
    border: `1px solid ${COLORS['primary-500']}`
  },
  '&.mobile': {
    width: thumbPhoneWidth,
    '& .MuiFormControl-root': {
      maxWidth: '170px !important'
    }
  },
  '&.tablet': {
    width: thumbTabletWidth
  },
  '&.desktop': {
    width: thumbDesktopWidth
  },
  '& .row': {
    display: 'flex',
    flexDirection: 'row'
  }
})
