import VideoCallIcon from '@mui/icons-material/VideoCall'
import { styled, useMediaQuery } from '@mui/material'
import Spline from '@splinetool/react-spline'
import React, { useState } from 'react'

import PBVisionEye from '@/assets/pb-eye.svg?react'
import PBVisionLogo from '@/assets/pb-vis-horiz-logo.svg?react'
import { Button } from '@/components/button'
import GetOnMobile from '@/components/get-on-mobile/get-on-mobile'
import { UploadDialog } from '@/components/upload-dialog'
import { useDeviceOS, DeviceOS } from '@/hooks/use-device-os'
import cls from '@/utils/classnames'
import { column } from '@/utils/flexGrid'

export function HeroLandingSection () {
  const [isUploadDialogOpened, setDoesUploadDialogOpened] = useState(false)
  const { deviceOS } = useDeviceOS()
  const supportedForDesktopVersion = useMediaQuery('(min-width:900px)')

  const isOtherDevices = deviceOS === DeviceOS.OTHER

  return (
    <Container className={cls(!supportedForDesktopVersion && 'mobile')}>
      <PBVisionLogo width={220} height={48} />
      <h2>
        Game-changing analysis for <br /> game-winning results
      </h2>
      <p>Revolutionize your play with <br />AI-driven insights</p>
      {supportedForDesktopVersion && <Button className='green' endIcon={<VideoCallIcon />} onClick={() => setDoesUploadDialogOpened(true)}>Get Started</Button>}
      {!supportedForDesktopVersion && isOtherDevices && (
        <div className='store-icons'>
          <GetOnMobile deviceOs={DeviceOS.ANDROID} />
          <GetOnMobile deviceOs={DeviceOS.IOS} />
        </div>
      )}
      {!supportedForDesktopVersion && !isOtherDevices && <GetOnMobile />}
      {supportedForDesktopVersion && (
        <div className='animation'>
          <PBVisionEye className='pb-vision-eye' />
          <div className='ball-container'>
            <Spline scene='https://prod.spline.design/culXms6oCQtagBeG/scene.splinecode' />
          </div>
        </div>
      )}
      <UploadDialog open={isUploadDialogOpened} onClose={() => setDoesUploadDialogOpened(false)} />
    </Container>
  )
}

const Container = styled('div')({
  ...column,
  alignItems: 'flex-start',
  gap: '48px',
  position: 'relative',
  paddingTop: '150px',
  marginBottom: '48px',
  '& h2': {
    fontSize: '64px',
    lineHeight: '69.12px',
    letterSpacing: '-2.62px',
    fontWeight: 700
  },
  '& p': {
    fontSize: '36px',
    lineHeight: '51.12px',
    fontWeight: 300
  },
  '& button': {
    padding: '10px 40px',
    fontSize: '19x'
  },
  '& .store-icons': {
    display: 'flex',
    flexDirection: 'column'
  },
  '& .animation': {
    position: 'absolute',
    // pointerEvents: 'none',
    right: '-80px',
    top: '48px',
    '& .pb-vision-eye': {
      zIndex: -1
    },
    '& .ball-container': {
      width: '100%',
      height: '60%',
      position: 'absolute',
      top: 0,
      left: 0,
      marginTop: '30%'
    }
  },
  '&.mobile': {
    gap: '32px',
    alignItems: 'center',
    marginBottom: '32px',
    padding: '32px 16px 0 16px',
    '& h2': {
      fontSize: '49px',
      lineHeight: '51px',
      letterSpacing: '-2.62px',
      marginRight: 'auto'
    },
    '& p': {
      fontSize: '24px',
      lineHeight: '34px',
      fontWeight: 300,
      marginRight: 'auto'
    },
    '& .download-button': {
      height: '64px',
      width: 'auto'
    }
  }
})
