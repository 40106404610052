import { styled } from '@mui/material'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { PlayerStatsContext } from '../index'

import { Avatar } from '@/components/avatar'
import { InlineEdit } from '@/components/inline-edit'
import { Percentile } from '@/components/percentile'
import { PlayerImage } from '@/components/player-image'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useMayEditVideo } from '@/store/video'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { round } from '@/utils/helper'
import { calculatePercentile } from '@/utils/helpers/insights'

export default function StatsHeadInfo (props) {
  const context = useContext(PlayerStatsContext)
  const insights = context?.insights
  const workflowContext = context?.workflow
  const { vid } = useParams()
  const mayEdit = useMayEditVideo(vid)
  const playerIdx = props.playerIdx
  const isMobile = useMobileDetect()

  const aiEngineVersion = workflowContext?.aiEngineVersion

  if (!insights || !aiEngineVersion) {
    return null
  }

  return (
    <Container className={`player${playerIdx + 1} ${isMobile && 'mobile'}`}>
      <PlayerImage className='player-image' vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={playerIdx} text={props.player.name || `Player ${playerIdx + 1}`} onChange={props.onRenamePlayer(playerIdx)} fit />
      <div className='info'>
        <div className='info-head'>
          <Avatar className='avatar' width='35' initials='P' round={playerIdx < 2} />
          <InlineEdit text={props.player.name || `Player ${playerIdx + 1}`} onChange={props.onRenamePlayer(playerIdx)} mayEdit={mayEdit} />
        </div>
        <strong className='total-shots'>Total Shots: {insights.player_stats[playerIdx].shot_count || 0}</strong>
        <div className='percentiles'>
          <div className='percentile'>
            <div className='percentile-head'>
              <strong>Serve</strong>
              <p>Percentile</p>
            </div>
            <div className='statistic'>
              <p>Median Serve:</p>
              <strong>{round(insights.player_stats[playerIdx].shot_speeds.serve?.median)}mph</strong>
              <Percentile
                value={calculatePercentile(round(insights.player_stats[playerIdx].shot_speeds.serve?.median))}
                color={COLORS['primary-500']}
              />
            </div>
          </div>
          <div className='percentile'>
            <div className='percentile-head'>
              <strong>Drive</strong>
              <p>Percentile</p>
            </div>
            <div className='statistic'>
              <p>Median Drive:</p>
              <strong>{round(insights.player_stats[playerIdx].shot_speeds.drive?.median)}mph</strong>
              <Percentile
                value={calculatePercentile(round(insights.player_stats[playerIdx].shot_speeds.drive?.median), 'drive')}
                color={COLORS.player3}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const Container = styled('div')({
  display: 'flex',
  padding: '32px 32px 10px 32px',
  gap: '32px',
  color: COLORS['neutral-800'],
  '& .info': {
    ...column,
    gap: 20,
    '& .info-head': {
      ...row,
      gap: 16,
      '& span': {
        color: COLORS['neutral-800']
      },
      '& .player-name': {
        fontWeight: 700,
        lineHeight: '133%',
        color: COLORS['neutral-800'],
        '& .editing': {
          marginBottom: 1.89
        }
      }
    },
    '& .total-shots': {
      fontSize: 20,
      color: COLORS['neutral-800'],
      lineHeight: '31px'
    },
    '& .percentiles': {
      ...row,
      gap: 50,
      fontSize: 14,
      color: COLORS['neutral-800'],
      '& .percentile': {
        '& .percentile-head': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${COLORS['neutral-200']}`
        }
      },
      '& .statistic': {
        ...row,
        alignItems: 'center',
        '& strong': {
          marginRight: 5
        }
      }
    }
  },
  '&.mobile': {
    ...column,
    padding: '15px 15px 25px 15px',
    '& .player-image': {
      width: '100%'
    },
    '& .percentiles': {
      ...column

    }
  }
})
