import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MuiButton from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import React from 'react'
import { Link } from 'react-router-dom'

import COLORS from '@/utils/colors'

export const Button = styled(MuiButton)({
  textTransform: 'none',
  fontWeight: 800,
  fontSize: '14px',
  lineHeight: '14px',
  height: '32px',
  backgroundColor: '#fff',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: COLORS['neutral-300'],
  color: `${COLORS['neutral-700']} !important`,
  marginLeft: '8px',
  '&:hover': {
    backgroundColor: '#fff',
    borderColor: COLORS['neutral-500']
  },
  '& .MuiSvgIcon-root': {
    color: COLORS['primary-500'],
    marginLeft: '8px'
  }
})

export const CreateAccountMessage = () => {
  return (
    <>
      Signing up is fast and free
      <Button LinkComponent={Link} to='/create-account'>
        Create Account<AccountCircleIcon />
      </Button>
    </>
  )
}
