import { createContext, useContext, useState } from 'react'

import ConfirmationDialog from '@/components/dialog/ConfirmationDialog'

const DialogContext = createContext()

/**
 * Custom hook to use the Dialog context.
 * @returns {object} The Dialog context value.
 */
export const useDialog = () => useContext(DialogContext)

/**
 * Provider component for dialog.
 *
 * @component
 * @param {object} props
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The DialogProvider component.
 */
export function DialogProvider ({ children }) {
  const [dialog, setDialog] = useState({})

  /**
   * Function to show the dialog.
   *
   * @param {object} params
   * @param {string} params.title - The title of the dialog.
   * @param {string} params.description - The description of the dialog.
   * @param {string} params.disagreeText - The text for the disagree button.
   * @param {string} params.agreeText - The text for the agree button.
   * @param {Function} params.onAccept - The function to call when the agree button is clicked.
   * @param {Function} params.onCancel - The function to call when the disagree button is clicked.
   */
  const showDialog = ({ title, description, disagreeText, agreeText, onAccept, onCancel }) => {
    setDialog({ isOpen: true, title, description, disagreeText, agreeText, onAccept, onCancel })
  }

  return (
    <DialogContext.Provider value={showDialog}>
      <ConfirmationDialog {...dialog} closeDialog={() => setDialog({})} />
      {children}
    </DialogContext.Provider>
  )
}
