import { styled } from '@mui/material/styles'

import COLORS from '@/utils/colors'

/**
 * Simple divider used to visually separate flexbox containers (like HR)
 *
 * @exports
 * @returns {React.ReactElement}
 */
export const Divider = styled('div')({
  backgroundColor: COLORS['neutral-500'],
  flexGrow: 1,
  height: '1px',
  opacity: '0.3',
  width: '100%'
})
