import { useMemo } from 'react'

/**
 * @typedef {object} ShotWithContext
 * @property {int} rallyIdx the rally in which the shot was hit (0-indexed)
 * @property {Rally} rally see insights documentation (https://pbv-public.github.io/insights?s=%2Finsights%2Fgame&p=rallies)
 * @property {Moment} moment see insights documentation (https://pbv-public.github.io/insights?s=%2Finsights%2Fgame&p=rallies.moments)
 * @property {Shot} shot see insights documentation (https://pbv-public.github.io/insights/?s=%2Finsights%2Fgame&p=rallies.moments.ball.shot)
 * @property {int} shotIdx how many shots were hit before this one in the rally (0 means this is the first shot in the rally)
 */

/**
 * @param {Insights} [insights]
 * @returns {Array<ShotWithContext>}
 */
export function useShotsWithContext (insights) {
  return useMemo(() => {
    const shotsWithContext = []
    let rallyIdx = 0
    const rallies = insights?.rallies ?? []

    for (;rallyIdx < rallies.length; rallyIdx++) {
      const rally = rallies[rallyIdx]
      let shotIdx = 0
      let momentIdx = 0

      // Filter out moments to contain only the shots in order
      // to use the next shot start time as end time for the current one
      const moments = rally.moments.filter((m) => m?.ball?.shot)

      for (;momentIdx < moments.length; momentIdx++) {
        const moment = moments[momentIdx]
        const { ball } = moment // ball is optional

        if (ball?.shot) {
          const mStart = moment.ms
          let mEnd = 0
          const nextMoment = moments[momentIdx + 1]
          if (nextMoment) {
            mEnd = nextMoment.ms
          } else {
            mEnd = rally.end_ms
          }

          // Needs to be here in order to properly combine with other filters,
          // once the collection is filtered - sequence is lost
          let shotSequence
          if (shotIdx === 0) shotSequence = 'serve'
          else if (shotIdx === 1) shotSequence = 'return'
          else if (shotIdx === 2) shotSequence = '3'
          else if (shotIdx === 3) shotSequence = '4'

          shotsWithContext.push({
            shot: ball.shot,
            moment,
            rally,
            rallyIdx,
            shotIdx,
            shotSequence,

            // Put frequently used props in here to speedup the reading
            rStart: rally.start_ms,
            rEnd: rally.end_ms,
            mStart,
            mEnd
          })
          shotIdx += 1
        }
      }
    }

    return shotsWithContext
  }, [insights?.rallies])
}

export function useFilteredShotsWithContext (insights, predicate) {
  const shotsWithContext = useShotsWithContext(insights)
  return useMemo(() => {
    return shotsWithContext.filter(predicate)
  }, [predicate, shotsWithContext])
}
