import { useContext, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { PageContext } from '../context'
import Folder from '../folder'

import styles from './styles.module.css'
import UploadVideoThumb from './upload-video-thumb'
import VideoThumb from './video-thumb'

import { Spinner } from '@/components/spinner'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { displayItemsByFolder } from '@/pages/library/display-items-by-folder'

function AlignmentPlaceholders () {
  const placeholders = []
  const NUMBER_OF_PLACEHOLDERS = 30
  for (let i = 0; i < NUMBER_OF_PLACEHOLDERS; i++) {
    placeholders.push(<div key={i} className={styles.placeholder} />)
  }

  return <>{placeholders.map((el) => el)}</>
}

function DisplayLibraryItem (item) {
  if (item?.vid) {
    return <VideoThumb videoExcerpt={item} />
  }
  if (item?.fid) {
    return <Folder {...item} />
  }
}

/**
 * GridView component
 *
 * @exports
 * @returns {React.ReactElement} GridView component
 */
function GridView () {
  const { fid } = useParams()
  const containerRed = useRef()

  const { filteredVideoExcerpts, isLoading, isInPublicLibrary } = useContext(PageContext)
  const isMobile = useMobileDetect()

  const renderItems = useMemo(() => displayItemsByFolder(filteredVideoExcerpts, fid), [fid, filteredVideoExcerpts])

  return (
    <>
      <div ref={containerRed} className={styles.thumbsContainer}>
        {renderItems?.map((videoExcerpt, i) => <DisplayLibraryItem key={i} {...videoExcerpt} />
        )}
        {!isLoading && !isInPublicLibrary && <UploadVideoThumb />}
        {!isMobile && <AlignmentPlaceholders />}
      </div>
      <Spinner hide={!isLoading} text='Loading library' />
    </>
  )
}

export default GridView
