import Page from './index'

import { Account } from '@/components/account'

export default [
  {
    path: '/settings/*',
    element: <Page />,
    children: [
      {
        path: 'account',
        element: <Account />
      }
    ]
  }
]
