export const isProd = import.meta.env.VITE_PROJECT_ID === 'pbv-prod'
export const isDebugMode = !isProd || window.location.hostname.startsWith('localhost')

export function assert (expr, msg, context = null) {
  if (!expr) {
    if (context) {
      console.error(msg, context)
    }
    throw new Error(`assert failed${msg ? `: ${msg}` : ''}`)
  }
}
assert.fail = (msg, context = null) => assert(false, msg, context)

/** Returns the current millisecond epoch */
export const millinow = () => new Date().getTime()

/** Returns the current epoch */
export const now = (isFloored = false) => {
  const epoch = millinow() / 1000
  return isFloored ? Math.floor(epoch) : epoch
}

export async function sleep (millis) {
  return new Promise(resolve => setTimeout(resolve, millis))
}
