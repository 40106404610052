import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { PlayerImage } from '../player-image'

import { Avatar } from '@/components/avatar'
import { Button } from '@/components/button'
import { Counter } from '@/components/counter'
import { Divider } from '@/components/divider'
import { InlineEdit } from '@/components/inline-edit'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { showCreateAccountBanner } from '@/store/anonymous'
import { setVideoField, useMayEditVideo } from '@/store/video'
import cl from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'

const buttonStyle = {
  alignSelf: 'center',
  boxShadow: COLORS['joy-shadow-xs'],
  padding: '2px 6px',
  minWidth: 56,
  minHeight: 56,
  '& svg': { marginLeft: 0, color: '#323232' }
}

const scoreOptions = [...Array(26).keys()]
const scoreColors = { won: COLORS['primary-500'], lost: '#f00' }

/**
 * Overview container
 *
 * @returns {React.ReactElement}
 */
const Container = styled('div')({
  ...row,
  color: COLORS['00-on-surface-high-emphasis'],
  padding: '0 32px 0 32px',
  marginBottom: 40,
  flexWrap: 'wrap',

  '& .column': {
    ...column
  },
  '& .team-container': {
    flex: 1
  },
  '& .img': {
    '&:first-of-type': {
      marginBottom: 8
    },
    // Position avatar initials relative to the image rectangle
    '&.player1 div': { top: 14 },
    '&.player2 div': { bottom: 14 },
    '&.player3 div': { top: 14 },
    '&.player4 div': { bottom: 14 },
    '&.player1, &.player2': {
      '& div': {
        right: -18
      }
    },
    '&.player3, &.player4': {
      '& div': {
        left: -28
      }
    },
    '& div': {
      // Position the avatar absolutely in the container
      position: 'absolute'
    },
    // Preview image box / color
    '&.player1': {
      color: COLORS.player1,
      border: '3px solid currentColor'
    },
    '&.player2': {
      color: COLORS.player2,
      border: '3px solid currentColor'
    },
    '&.player3': {
      color: COLORS.player3,
      border: '3px solid currentColor'
    },
    '&.player4': {
      color: COLORS.player4,
      border: '3px solid currentColor'
    }
  },
  '& .grow': {
    flexGrow: 1
  },
  '& .player': {
    fontSize: 20,
    fontWeight: 600,
    alignItems: 'center',
    ...row,
    '& button span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '120px',
      width: 'unset !important'
    }
  },
  '& .team': {
    fontSize: 14,
    fontWeight: 300,
    justifyContent: 'center',
    maxHeight: 14,
    ...column
  },
  '& .result': {
    alignItems: 'center',
    alignSelf: 'center',
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.5,
    textAlign: 'center',

    '& > span': {
      marginBottom: 5
    },
    '& button': {
      backgroundColor: '#fff'
    }
  },
  '& .items-center': {
    alignItems: 'center'
  },
  '& .remove-icon': {
    backgroundColor: COLORS.black,
    height: 3,
    width: 12,
    margin: '0 12px'
  },
  '& .ml26': {
    marginLeft: 26
  },
  '& .mh72': {
    maxHeight: 72
  },
  '& .m40': {
    marginRight: 40
  },
  '& .tar': {
    textAlign: 'right'
  },
  '& .edit-ico': {
    cursor: 'pointer',
    fontSize: 18,
    color: '#BDBDBD',
    margin: '0 8px'
  },
  '& button.won': {
    color: scoreColors.won,
    textTransform: 'capitalize'
  },
  '& button.lost': {
    color: scoreColors.lost,
    textTransform: 'capitalize'
  },
  // Inline edit size fix
  '& .editing input': {
    padding: '2px 4px'
  },
  '@media (min-width: 568px)': {
    '& .align-right': {
      justifyContent: 'flex-end',
      '& span': {
        justifyContent: 'flex-end'
      }
    }
  },
  // Mobile style overrides
  '&.mobile': {
    flexDirection: 'column',
    '& .img.player3, & .img.player4': {
      '& div': {
        right: -28,
        left: 'auto'
      }
    },
    '& .result': {
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '16px 0',
      width: '100%',
      '& span': {
        marginRight: 17
      },
      '& > .row': {
        flexDirection: 'column',
        width: '100%',
        '& > div': {
          width: '100%',
          '& button': {
            width: '100%',
            borderColor: COLORS['neutral-300'],
            borderRadius: 12
          }
        },
        '& > .remove-icon': {
          alignItems: 'center',
          color: '#000',
          backgroundColor: 'unset',
          display: 'flex',
          fontSize: 16,
          fontWeight: 400,
          gap: 16,
          height: 'unset',
          margin: '24px 0',
          position: 'relative',
          '&:before, &:after': {
            content: '""',
            flexGrow: 1,
            height: 3,
            backgroundColor: COLORS['neutral-300']
          }
        }
      }
    },
    '& .team2': {
      order: 2,
      '& .tar': {
        textAlign: 'left'
      }
    },
    '& .m40': {
      marginRight: 0,
      marginLeft: 37
    },
    '& .ml26': {
      marginLeft: 28
    }
  }
})

export function useRenamePlayer () {
  const dispatch = useDispatch()
  return useCallback((video, playerIndex, newName) => {
    const { userData } = video
    // players might have fewer than playerIndex+1 players (they aren't set
    // until they're needed)
    const playersCopy = userData.players.map(x => ({ ...x }))
    if (newName && playersCopy[playerIndex]?.name !== newName) {
      while (playersCopy.length <= playerIndex) {
        playersCopy.push({})
      }
      playersCopy[playerIndex].name = newName
      dispatch(setVideoField(video.vid, 'players', playersCopy))
    }
  }, [dispatch])
}

/**
 * Overview component
 *
 * @exports
 * @returns {React.ReactElement} Overview component
 */
export function Overview ({ insights, video, workflow }) {
  const dispatch = useDispatch()
  const isMobile = useMobileDetect()
  const { vid } = useParams()
  const [scoreAnchorEl, setScoreAnchorElInState] = useState(null)
  const isScoreAnchorEl = Boolean(scoreAnchorEl)
  const mayEdit = useMayEditVideo(vid)
  const setScoreAnchorEl = useCallback(newValue => {
    if (!newValue || mayEdit) {
      setScoreAnchorElInState(newValue)
    } else {
      dispatch(showCreateAccountBanner(true))
    }
  }, [dispatch, mayEdit])

  const closeScoreMenu = () => {
    setScoreAnchorEl(null)
  }
  const { userData } = video
  const { game_stats: gameStats, rallies } = insights
  const { aiEngineVersion } = workflow

  const renamePlayerIfNeeded = useRenamePlayer()
  const onRenamePlayer = useCallback(
    (index) => (update) => { renamePlayerIfNeeded(video, index, update.text) },
    [renamePlayerIfNeeded, video]
  )

  const gameOutcome = userData.teamOutcomes ?? gameStats.game_outcome
  const handleScore = (val) => {
    closeScoreMenu()
    const teamIndex = scoreAnchorEl.id === 'team1' ? 0 : scoreAnchorEl.id === 'team2' ? 1 : null
    if (teamIndex === null) return
    let newScores = [...gameOutcome]
    if (val === 'Clear') {
      newScores = []
    } else {
      const smallVal = String(val).toLowerCase()
      if (['won', 'lost'].includes(smallVal)) {
        newScores[teamIndex] = smallVal
        newScores[teamIndex === 0 ? 1 : 0] = smallVal === 'won' ? 'lost' : 'won'
      } else {
        newScores[teamIndex] = Number(smallVal)
      }
    }
    dispatch(setVideoField(vid, 'teamOutcomes', newScores))
  }

  return (
    <>
      <Container className={cl(isMobile && 'mobile')}>
        <div className='team-container'>
          <div className='row'>
            <div className='column'>
              <PlayerImage className='img player1' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={0} text={userData.players[0]?.name || 'Player 1'} onChange={onRenamePlayer(0)}>
                <Avatar width='35' initials='P' round />
              </PlayerImage>
              <PlayerImage className='img player2' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={1} text={userData.players[1]?.name || 'Player 2'} onChange={onRenamePlayer(1)}>
                <Avatar width='35' initials='P' round />
              </PlayerImage>
            </div>
            <div className='column'>
              <div className='player grow ml26 mh72'>
                <InlineEdit text={userData.players[0]?.name || 'Player 1'} onChange={onRenamePlayer(0)} mayEdit={mayEdit} />
              </div>
              <div className='team grow ml26'>Team A</div>
              <div className='player grow ml26 mh72'>
                <InlineEdit text={userData.players[1]?.name || 'Player 2'} onChange={onRenamePlayer(1)} mayEdit={mayEdit} />
              </div>
            </div>
          </div>
        </div>
        <div className='column result'>
          {!isMobile && <span>Score</span>}
          <div className='row items-center'>
            <div>
              <Button
                id='team1'
                variant='outlined'
                color='midnight'
                className={cl(gameOutcome[0] === 'won' && 'won', gameOutcome[0] === 'lost' && 'lost')}
                sx={buttonStyle}
                onClick={(event) => {
                  setScoreAnchorEl(event.currentTarget)
                }}
              >
                {gameOutcome[0] ?? <ArrowDropDownIcon />}
              </Button>
            </div>
            <div className='remove-icon'>{isMobile ? 'Score' : ''}</div>
            <div>
              <Button
                id='team2'
                variant='outlined'
                color='midnight'
                className={cl(gameOutcome[1] === 'won' && 'won', gameOutcome[1] === 'lost' && 'lost')}
                size='large'
                sx={buttonStyle}
                onClick={(event) => {
                  setScoreAnchorEl(event.currentTarget)
                }}
              >
                {gameOutcome[1] ?? <ArrowDropDownIcon />}
              </Button>
            </div>
          </div>
          <Menu
            id='score-button'
            anchorEl={scoreAnchorEl}
            open={isScoreAnchorEl && mayEdit}
            onClose={closeScoreMenu}
            sx={{ '& li': { justifyContent: 'center' } }}
            MenuListProps={{ 'aria-labelledby': 'score-button' }}
            slotProps={{
              paper: {
                style: {
                  maxHeight: 440
                }
              }
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top'
            }}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom'
            }}
          >
            <MenuItem onClick={() => handleScore('Clear')}>-</MenuItem>
            <MenuItem
              onClick={() => handleScore('won')}
              sx={{ color: scoreColors.Won }}
              selected={scoreAnchorEl && gameOutcome[scoreAnchorEl.id === 'team1' ? 0 : 1] === 'won'}
            >
              Won
            </MenuItem>
            <MenuItem
              onClick={() => handleScore('Lost')}
              sx={{ color: scoreColors.Lost }}
              selected={scoreAnchorEl && gameOutcome[scoreAnchorEl.id === 'team1' ? 0 : 1] === 'Lost'}
            >
              Lost
            </MenuItem>
            <Divider />
            {scoreOptions.map((val) => (
              <MenuItem
                key={val}
                onClick={() => handleScore(val)}
                selected={scoreAnchorEl && gameOutcome[scoreAnchorEl.id === 'team1' ? 0 : 1] === val}
              >
                {val}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <div className='team-container '>
          <div className='row align-right'>
            <div className='column team2'>
              <div className='player grow m40 tar mh72'>
                <InlineEdit text={userData.players[2]?.name || 'Player 3'} onChange={onRenamePlayer(2)} mayEdit={mayEdit} buttonLeft />
              </div>
              <div className='team grow m40 tar'>Team B</div>
              <div className='player grow m40 tar mh72'>
                <InlineEdit text={userData.players[3]?.name || 'Player 4'} onChange={onRenamePlayer(3)} mayEdit={mayEdit} buttonLeft />
              </div>
            </div>
            <div className='column'>
              <PlayerImage className='img player3' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={2} text={userData.players[2]?.name || 'Player 3'} onChange={onRenamePlayer(2)}>
                <Avatar width='35' initials='P' />
              </PlayerImage>
              <PlayerImage className='img player4' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={3} text={userData.players[3]?.name || 'Player 4'} onChange={onRenamePlayer(3)}>
                <Avatar width='35' initials='P' />
              </PlayerImage>
            </div>
          </div>
        </div>
      </Container>
      <Divider sx={{ margin: '0 32px 32px', width: 'unset' }} />
      {workflow.epochFinished && (
        <Counter
          totalRallies={rallies.length}
          kitchenRallies={gameStats.kitchen_rallies}
          avgShotsPerRally={gameStats.avg_shots}
          longestRally={gameStats.longest_rally?.num_shots}
        />
      )}
    </>
  )
}
