import { styled } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import * as React from 'react'

import { Button } from '../button'

import useMobileDetect from '@/hooks/use-mobile-detect'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'

/**
 * Transition component for the dialog, slides up from the bottom.
 *
 * @param {object} props
 * @param {React.Ref} ref - The ref for the component.
 * @returns {JSX.Element} The Transition component.
 */
const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

/**
 * ConfirmationDialog component that displays a dialog with a title, description, and action buttons.
 *
 * @component
 * @param {object} props
 * @param {boolean} props.isOpen - Whether the dialog is open.
 * @param {string} [props.title] - The title of the dialog.
 * @param {string} [props.description] - The description of the dialog.
 * @param {string} [props.disagreeText] - The text for the disagree button.
 * @param {string} [props.agreeText] - The text for the agree button.
 * @param {string} [props.disagreeButtonClasses] - The additional classes for the agree button.
 * @param {string} [props.confirmButtonClasses] - The additional classes for the agree button.
 * @param {Function} props.onAccept - The function to call when the agree button is clicked.
 * @param {Function} props.onCancel - The function to call when the disagree button is clicked.
 * @returns {JSX.Element} The ConfirmationDialog component.
 */
export default function ConfirmationDialog (props, ref) {
  const isMobile = useMobileDetect()

  const isOpen = React.useMemo(() => {
    return !!props.isOpen
  }, [props.isOpen])

  const handleClose = () => {
    props.closeDialog()
  }

  const handleCancel = () => {
    props.closeDialog()
    props?.onCancel && props.onCancel()
  }

  const handleAccept = () => {
    props.closeDialog()
    props.onAccept && props.onAccept()
  }

  return (
    <>
      <StyledDialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <div className={`content ${isMobile && 'mobile'}`}>
          {props.title && (<h2>{props.title}</h2>)}
          {props.description && (<p>{props.description}</p>)}
          <div className='actions'>
            <Button
              variant='outlined'
              className={cls(props?.disagreeButtonClasses || ('bold', 'danger'))}
              onClick={handleCancel}
            >
              {props.disagreeText || 'Cancel'}
            </Button>
            <Button
              variant='outlined'
              className={cls(props?.confirmButtonClasses || ('bold', 'gray'))}
              onClick={handleAccept}
            >
              {props.agreeText || 'OK'}
            </Button>
          </div>
        </div>
      </StyledDialog>
    </>
  )
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  zIndex: 1301,
  '& .MuiDialog-paper': {
    backgroundColor: COLORS['neutral-050'],
    border: `1px solid ${COLORS['neutral-300']}`
  },
  '& .actions': {
    display: 'flex',
    marginTop: 16,
    width: '100%',
    gap: '8px',
    justifyContent: 'center'
  },
  '& .content': {
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 16,
    minWidth: 300,
    textAlign: 'center'
  },
  '& button': {
    paddingInline: '12px'
  },
  '& h2': {
    fontSize: 14,
    color: COLORS['neutral-800'],
    fontWeight: 600
  },
  '& p': {
    fontSize: 12,
    color: COLORS['neutral-800'],
    fontWeight: 500
  },
  // responsive (mobile)
  '& .content.mobile': {
    '& .actions': {
      flexDirection: 'column'
    }
  }
}))
