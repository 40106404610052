import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'

import { rallySequence, shotTypes } from '../helpers'

import { chipStyles } from './chip-select'

const FilterList = (props) => {
  const { filter, filters, clear, stats } = props

  return filters.map((option) => {
    let label = Object.hasOwn(option, 'label') ? option.label : option
    const value = Object.hasOwn(option, 'value') ? option.value : option

    if (stats?.[value]) {
      label = <>{label}<span className='badge'>{stats[value]}</span></>
    }
    return (
      <Chip
        variant='outlined'
        label={label}
        key={value}
        onDelete={() => clear(filter, value)}
      />
    )
  })
}

export const CurrentFilters = (props) => {
  const { filters, updateFilter, stats, userData } = props
  const clearFilter = (filterType, value) => {
    if (filterType === 'players') {
      // Prevent deselecting all of the players
      if (filters.players.length > 1) {
        updateFilter('players', filters.players.filter((p) => p !== value))
      }
    } else if (filterType === 'shotTypes') {
      updateFilter('types', filters.types.filter((t) => t !== value))
    } else if (filterType === 'sequences') {
      updateFilter('sequences', filters.sequences.filter((t) => t !== value))
    }
  }

  return (
    <Stack direction='row' spacing={1} sx={chipStyles}>
      <FilterList filters={filters.players.map((p) => ({ value: p, label: userData.players[p]?.name || `Player ${p + 1}` }))} filter='players' clear={clearFilter} />
      <FilterList filters={shotTypes.filter((t) => filters.types.includes(t.value))} filter='shotTypes' clear={clearFilter} stats={stats} />
      <FilterList filters={rallySequence.filter((t) => filters.sequences.includes(t.value))} filter='sequences' clear={clearFilter} stats={stats} />
    </Stack>
  )
}
