export const useBulkSelect = (selectedArray = []) => {
  const isItemSelected = (item) => {
    return item?.vid
      ? selectedArray?.some(existing => existing.vid === item.vid)
      : selectedArray?.some(existing => existing.fid === item.fid)
  }

  const toggleItemSelection = (item) => {
    if (isItemSelected(item)) {
      return item?.vid
        ? selectedArray.filter(existing => existing.vid !== item.vid)
        : selectedArray.filter(existing => existing.fid !== item.fid)
    } else {
      return [...selectedArray, item]
    }
  }

  return { toggleItemSelection, isItemSelected }
}
