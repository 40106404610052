/// <reference types="vite-plugin-svgr/client" />

import { styled } from '@mui/material/styles'
import { useCallback } from 'react'

import { useRenamePlayer } from '../overview'
import { PlayerImage } from '../player-image'

import { Avatar } from '@/components/avatar'
import { BarChart } from '@/components/bar-chart'
import { CourtKitchen } from '@/components/court-kitchen'
import { InlineEdit } from '@/components/inline-edit'
import useMobileDetect from '@/hooks/use-mobile-detect'
import { useMayEditVideo } from '@/store/video'
import cls from '@/utils/classnames'
import COLORS from '@/utils/colors'
import { column, row } from '@/utils/flexGrid'
import { round } from '@/utils/helper'

/**
 * TeamStats container
 *
 * @returns {React.ReactElement}
 */
const Container = styled('div')({
  ...column,
  padding: '0 32px',

  '& .flex-1': {
    flex: 1
  },

  '& .column': {
    ...column
  },
  '& .row': {
    ...row
  },
  '& .grow': {
    flexGrow: 1
  },
  '& .justify-end': {
    justifyContent: 'flex-end'
  },
  '& .gap50': {
    gap: 50
  },
  '& .title': {
    color: COLORS['neutral-800'],
    fontSize: 20,
    fontWeight: 600
  },
  '& .team2 .title': {
    textAlign: 'right'
  },
  '& .player': {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 600,
    color: COLORS['00-on-surface-high-emphasis']
  },
  '& .team2 .player': {
    justifyContent: 'flex-end'
  },
  '& .img': {
    '&:first-of-type': {
      // Separate player images vertically
      marginBottom: 8
    },
    '&.player1 div': { top: 14 },
    '&.player2 div': { bottom: 14 },
    '&.player3 div': { top: 14 },
    '&.player4 div': { bottom: 14 },
    '&.player1, &.player2': {
      '& div': {
        right: -18
      }
    },
    '&.player3, &.player4': {
      '& div': {
        left: -28
      }
    },
    '& div': {
      position: 'absolute',
      height: 35,
      width: 35,
      alignItems: 'center',
      justifyContent: 'center',
      ...column
    }
  },
  '& .half-w': {
    width: '50%'
  },
  '& .ml20': {
    marginLeft: 20
  },
  '& .mt16': {
    marginTop: 16
  },
  '& .mt22': {
    marginTop: 22
  },
  '& .mr30': {
    marginRight: 30
  },
  '& .tar': {
    textAlign: 'right'
  },
  '& .body1': {
    color: COLORS['00-on-surface-high-emphasis'],
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.5,
    paddingBottom: 4
  },
  '& .ml-auto': {
    marginLeft: 'auto'
  },
  '& .edit-ico': {
    cursor: 'pointer',
    fontSize: 18,
    color: '#BDBDBD',
    margin: '0 5px'
  },
  '& .player1': {
    color: COLORS.player1
  },
  '& .player2': {
    color: COLORS.player2
  },
  '& .player3': {
    color: COLORS.player3
  },
  '& .player4': {
    color: COLORS.player4
  },
  // Inline edit
  '& .i-edit': {
    maxHeight: 79,

    '& button span': {
      whiteSpace: 'nowrap',
      fontSize: '16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 120,
      width: 'unset !important'
    },
    // Inline edit size fix
    '& .editing input': {
      padding: '4px',
      maxWidth: 'calc(100% - 20px)'
    },
    // Fix right side edit - left padding
    '&.tar': {
      marginLeft: 10
    }
  },
  '& .team-b': {
    '& span:first-of-type': {
      justifyContent: 'flex-end'
    }
  },
  // Mobile overrides
  '&.mobile': {
    '& .teams': {
      flexDirection: 'column',
      '& > .title': {
        marginBottom: 14
      },
      '& > .column': {
        flexDirection: 'row'
      },
      // Move court to the end
      '& > .court-kitchen': {
        order: 3
      },
      // Update team 2 alignments
      '& .team-b': {
        '& button': {
          order: 2
        },
        '& .img > div': {
          left: 'unset',
          right: -22
        },
        '& span:first-of-type': {
          justifyContent: 'flex-start'
        }
      }
    },
    '& .team2': {
      order: 2,
      '& .player': {
        marginLeft: 26
      }
    },
    '& .shot-distribution': {
      ...column,
      marginBottom: 50,

      '& .bar-title': {
        marginTop: 22,
        fontSize: 16,
        color: COLORS['00-on-surface-high-emphasis']
      }
    }
  }
})

/**
 * shows stats of both teams
 */
export function TeamStats ({ insights, video, workflow }) {
  const isMobile = useMobileDetect()
  const { userData, vid } = video
  const { player_stats: playerStats } = insights
  const { aiEngineVersion } = workflow
  const mayEdit = useMayEditVideo(vid)

  const renamePlayerIfNeeded = useRenamePlayer()
  const onRenamePlayer = useCallback(
    (index) => (update) => { renamePlayerIfNeeded(video, index, update.text) },
    [renamePlayerIfNeeded, video]
  )

  /* eslint-disable camelcase */
  const kitchenArrivals = playerStats.map(({ role_stats }) => {
    const calc =
      role_stats.serving.oneself.kitchen_arrival && role_stats.serving.oneself.total
        ? (role_stats.serving.oneself.kitchen_arrival + role_stats.serving.partner.kitchen_arrival) /
          (role_stats.serving.oneself.total + role_stats.serving.partner.total)
        : 0
    // [number of serving rallies where the player made it to the kitchen] / [number of total rallies where this player's team served] * 100%
    return round(calc * 100)
  })
  /* eslint-enable camelcase */

  return (
    <Container className={cls(isMobile && 'mobile')}>
      <div className='row teams'>
        {isMobile && <div className='title'>Team A Stats</div>}
        <div className='column flex-1 team-a'>
          <div className='row'>
            <div className='column'>
              <PlayerImage className='img player1' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={0} text={userData.players[0]?.name || 'Player 1'} onChange={onRenamePlayer(0)}>
                <Avatar width='35' initials='P' round />
              </PlayerImage>
              <PlayerImage className='img player2' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={1} text={userData.players[1]?.name || 'Player 2'} onChange={onRenamePlayer(1)}>
                <Avatar width='35' initials='P' round />
              </PlayerImage>
            </div>
            <div className='column grow'>
              <div className='player grow ml20 i-edit'>
                <InlineEdit text={userData.players[0]?.name || 'Player 1'} onChange={onRenamePlayer(0)} mayEdit={mayEdit} />
              </div>
              <div className='player grow ml20 i-edit'>
                <InlineEdit text={userData.players[1]?.name || 'Player 2'} onChange={onRenamePlayer(1)} mayEdit={mayEdit} />
              </div>
            </div>
          </div>
        </div>
        {isMobile && (
          <div className='shot-distribution'>
            <div className='bar-title'>Shot Distribution</div>
            <BarChart
              value1={playerStats[0].total_team_shot_percentage}
              fill1={COLORS.player1}
              fill2={COLORS.player2}
              sx={{ minHeight: 38 }}
            />
            <div className='bar-title'>Left side play</div>
            <BarChart
              value1={playerStats[0].left_side_percentage}
              value2={playerStats[1].left_side_percentage}
              fill1={COLORS.player1}
              fill2={COLORS.player2}
              sx={{ minHeight: 38 }}
            />
          </div>
        )}
        <CourtKitchen values={kitchenArrivals} />
        {isMobile && <div className='title'>Team B Stats</div>}
        <div className='column flex-1 team-b'>
          <div className='row justify-end'>
            <div className='column team2 grow'>
              <div className='player grow mr30 tar i-edit'>
                <InlineEdit text={userData.players[2]?.name || 'Player 3'} onChange={onRenamePlayer(2)} mayEdit={mayEdit} buttonLeft />
              </div>
              <div className='player grow mr30 tar i-edit'>
                <InlineEdit text={userData.players[3]?.name || 'Player 4'} onChange={onRenamePlayer(3)} mayEdit={mayEdit} buttonLeft />
              </div>
            </div>
            <div className='column'>
              <PlayerImage className='img player3' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={2} text={userData.players[2]?.name || 'Player 3'} onChange={onRenamePlayer(2)}>
                <Avatar width='35' initials='P' round />
              </PlayerImage>
              <PlayerImage className='img player4' width={75} height={75} vid={vid} aiEngineVersion={aiEngineVersion} playerIdx={3} text={userData.players[3]?.name || 'Player 4'} onChange={onRenamePlayer(3)}>
                <Avatar width='35' initials='P' round />
              </PlayerImage>
            </div>
          </div>
        </div>
        {isMobile && (
          <div className='shot-distribution'>
            <div className='bar-title'>Shot Distribution</div>
            <BarChart
              value1={playerStats[2].total_team_shot_percentage}
              fill1={COLORS.player3}
              fill2={COLORS.player4}
              sx={{ minHeight: 38 }}
            />
            <div className='bar-title'>Left side play</div>
            <BarChart
              value1={playerStats[2].left_side_percentage}
              value2={playerStats[3].left_side_percentage}
              fill1={COLORS.player3}
              fill2={COLORS.player4}
              sx={{ minHeight: 38 }}
            />
          </div>
        )}
        {isMobile && <div className='title'>Kitchen Arrival</div>}
      </div>
      {!isMobile && (
        <>
          <div className='row body1 mt16'>
            <div>Shot Distribution</div>
            <div className='ml-auto'>Shot Distribution</div>
          </div>
          <div className='row gap50'>
            <div className='column half-w'>
              <BarChart
                value1={playerStats[0].total_team_shot_percentage}
                fill1={COLORS.player1}
                fill2={COLORS.player2}
                sx={{ minHeight: 38 }}
              />
            </div>
            <div className='column half-w'>
              <BarChart
                value1={playerStats[2].total_team_shot_percentage}
                fill1={COLORS.player3}
                fill2={COLORS.player4}
                sx={{ minHeight: 38 }}
              />
            </div>
          </div>
          <div className='row body1 mt22'>
            <div>Left side play</div>
            <div className='ml-auto'>Left side play</div>
          </div>
          <div className='row gap50'>
            <div className='column half-w'>
              <BarChart
                value1={playerStats[0].left_side_percentage}
                value2={playerStats[1].left_side_percentage}
                fill1={COLORS.player1}
                fill2={COLORS.player2}
                sx={{ minHeight: 38 }}
              />
            </div>
            <div className='column half-w'>
              <BarChart
                value1={playerStats[2].left_side_percentage}
                value2={playerStats[3].left_side_percentage}
                fill1={COLORS.player3}
                fill2={COLORS.player4}
                sx={{ minHeight: 38 }}
              />
            </div>
          </div>
        </>
      )}
    </Container>
  )
}
