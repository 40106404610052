import CssBaseline from '@mui/material/CssBaseline'
import { styled } from '@mui/material/styles'
import { wrapUseRoutes } from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import { Suspense, useEffect, useState } from 'react'
import { useRoutes, Link, useLocation } from 'react-router-dom'

import { useAccountBanner } from './components/create-account-banner'
import ConfirmationDialog from './components/dialog/ConfirmationDialog'
import { SomethingWentWrong } from './components/error/SomethingWentWrong'
import { AlertBannerProvider } from './store/providers/alert-banner-provider'
import { DialogProvider } from './store/providers/dialog-provider'
import { SnackbarProvider } from './store/providers/snackbar-provider'
import { useUploadsManager } from './store/uploads'
import { isProd } from './utils'
import ErrorBoundary from './utils/error-handling/ErrorBoundary'

import { Footer } from '@/components/footer'
import { BaseNavbar } from '@/components/navbar'
import useLocationChange from '@/hooks/use-location-change'
import { useIsLoggedIn } from '@/store/auth'
import { isInMobileAppWebview, useListenForMobileAppCommands } from '@/utils/mobile-app-communication'
import routes from '@/utils/router'

const useSentryRoutes = wrapUseRoutes(useRoutes)

const Routes = () => {
  useAccountBanner()
  return useSentryRoutes(routes)
}

// report page views to analytics
function useRecordPageViewAnalytics () {
  const location = useLocation()
  const [path, setPath] = useState()
  const isLoggedIn = useIsLoggedIn()

  const currentPath = location.pathname
  useEffect(() => {
    setPath((oldPath) => {
      if (oldPath !== currentPath) {
        // omit the leading /
        mixpanel.track('page_view', { page: currentPath.substring(1) })
        if (isLoggedIn) {
          mixpanel.people.set({}) // update last_seen
        }
      }
      return currentPath
    })
  }, [currentPath, isLoggedIn, path])
}

function Layout ({ children }) {
  const path = useLocation().pathname

  return (
    <DialogProvider>
      <SnackbarProvider>
        <ErrorBoundary location={path} fallbackRender={SomethingWentWrong}>
          <ConfirmationDialog />
          {children}
        </ErrorBoundary>
      </SnackbarProvider>
    </DialogProvider>
  )
}

function App () {
  useListenForMobileAppCommands()
  useRecordPageViewAnalytics()
  useUploadsManager()
  useLocationChange()

  const path = useLocation().pathname

  // notify the native mobile app whenever we browse to a new route
  const setFirstPathElement = useState()[1]
  const currentFirstPathElement = path.split('/')[1]

  const preventBounceOnScroll = () => {
    const bodyEl = document.querySelector('body')
    bodyEl.style.overscrollBehavior = 'none'
  }

  useEffect(() => {
    if (isInMobileAppWebview()) {
      preventBounceOnScroll()
    }
  }, [])

  useEffect(() => {
    setFirstPathElement(old => {
      if (old !== currentFirstPathElement) {
        const mainElt = document.getElementById('pbvision') ?? document.getElementById('root')
        if (mainElt) {
          mainElt.scrollTo(0, 0)
        }
        return currentFirstPathElement
      }
    })
  }, [currentFirstPathElement, setFirstPathElement])

  useEffect(() => {
    const webflowCssLink = document.querySelector('link[data-webflow]')
    if (webflowCssLink) {
      if (path === '/') {
        // Enable the CSS for the landing page
        webflowCssLink.disabled = false
      } else {
        // Disable the CSS for other pages
        webflowCssLink.disabled = true
      }
    }
  }, [path])

  const shouldRenderHeader = !isInMobileAppWebview()
  const shouldRenderFooter = !isInMobileAppWebview()

  return (
    <div id='pbvision'>
      <Layout>
        <CssBaseline />
        {shouldRenderHeader && (
          <>
            <BaseNavbar />
            <GitVersion />
          </>
        )}
        {!shouldRenderHeader && <GitVersion />}

        <AlertBannerProvider>
          <Suspense>
            <div className='main'>
              <Routes />
            </div>
          </Suspense>
        </AlertBannerProvider>

        {shouldRenderFooter && <Footer />}
      </Layout>
    </div>
  )
}

function GitVersion () {
  if (!import.meta.env.VITE_RELEASE) {
    return
  }
  return (
    <GitVersionContainer
      className='g-version'
      sx={{ zIndex: 10000, display: isProd ? 'none' : 'inline-block' }}
    >
      <Link to={`https://github.com/pb-vision/insights/commit/${import.meta.env.VITE_RELEASE}`}>
        {import.meta.env.VITE_RELEASE.substring(0, 8)}
      </Link>
    </GitVersionContainer>
  )
}

const GitVersionContainer = styled('span')({
  color: '#AAA',
  display: import.meta.env.VITE_PROJECT_ID === 'pbv-prod' ? 'none' : 'inline-block',
  fontSize: '0.6rem',
  position: 'absolute',
  right: 0,
  top: 0
})

// eslint-disable-next-line import/no-default-export
export default App
