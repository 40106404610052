import { styled } from '@mui/material/styles'
import { useCallback, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Button } from '@/components/button'
import { Overview } from '@/components/overview'
import { PlayerStats } from '@/components/player-stats'
import { TeamStats } from '@/components/team-stats'
import { useURLParams } from '@/hooks/use-url-params'
import COLORS from '@/utils/colors'
import { row, column } from '@/utils/flexGrid'

/**
 * Tabs container containing all the style definitions
 *
 * @returns {React.ReactElement}
 */
const TabNode = styled('div')({
  borderRadius: '4px',
  border: `1px solid ${COLORS['neutral-200']}`,
  background: COLORS['neutral-100'],
  marginTop: 16,
  paddingBottom: 16,
  ...column,

  '& .row': {
    ...row
  },
  '& .gap': {
    gap: 16
  },
  '& .toolbar': {
    margin: '16px 16px 32px',

    '& .MuiButtonBase-root': {
      flexGrow: 1,
      backgroundColor: COLORS.white,

      '&[data-active="true"]': {
        backgroundColor: COLORS['primary-100'],
        boxShadow: `0 1px ${COLORS['primary-500']}`
      }
    }
  }
})

const TABS = [
  { id: 'overview', name: 'Overview' },
  { id: 'team', name: 'Team Stats' },
  { id: 'player', name: 'Player Stats' }
]

function Tabs (props) {
  const { tab } = useParams()
  if (tab === 'team') return <TeamStats {...props} />
  if (tab === 'overview') return <Overview {...props} />
  return <PlayerStats {...props} />
}

/**
 * TabView component - used to display the main tabs
 *
 * @exports
 * @returns {React.ReactElement}
 */
export function TabView ({ insights, video, workflow }) {
  const navigate = useNavigate()
  const { vid, tab } = useParams()
  const { nestParamsIntoURL } = useURLParams()
  const goToTab = useCallback((newTabName) => {
    const newTabURL = `/video/${vid}${newTabName ? `/${newTabName}` : 'player'}`
    navigate(nestParamsIntoURL(newTabURL))
  }, [nestParamsIntoURL, navigate, vid])

  // go to overview if the provided tab name is invalid (e.g., someone made a
  // typo when manually entering a URL
  useEffect(() => {
    if (!tab) {
      // Update URL without affecting the history stack
      navigate(nestParamsIntoURL(`/video/${vid}/player`), { replace: true })
    }
  }, [nestParamsIntoURL, navigate, tab, vid])

  return (
    <TabNode>
      <div className='row gap toolbar'>
        {TABS.map(({ id: tabId, name }, i) => (
          <Button
            key={tabId ?? i}
            variant='text'
            color='midnight'
            onClick={() => goToTab(tabId)}
            data-active={tab === tabId}
          >
            {name}
          </Button>
        ))}
      </div>
      <Tabs video={video} insights={insights} workflow={workflow} />
    </TabNode>
  )
}
