import clsx from 'clsx'
import { Link } from 'react-router-dom'

import AppStore from './assets/app-store-download.svg?react'
import GooglePlay from './assets/google-play-download.svg?react'
import PBVisionLogo from './assets/pb-vision-logo.svg?react'
import DevQRCode from './assets/pbv-dev-qr.svg?react'
import ProdQRCode from './assets/pbv-prod-qr.svg?react'
import ScanToDownloadArrow from './assets/scan-to-download-arrow.svg?react'
import Container from './container'

import DiscordLogo from '@/components/footer/assets/discord_logo.svg?react'
import InstagramLogo from '@/components/footer/assets/instagram_logo.svg?react'
import XLogo from '@/components/footer/assets/X_logo.svg?react'
import useMobileDetect from '@/hooks/use-mobile-detect'
import useZendesk from '@/hooks/use-zendesk'
import { useIsLoggedIn } from '@/store/auth'

const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.pb.vision.app'
const APP_STORE_LINK = 'https://apps.apple.com/us/app/pb-vision-uploader/id6467020610'

function LinkSection (props) {
  const { title, links } = props
  return (
    <div className='linkSection'>
      <div className='title'>{title}</div>
      <div className='links'>
        {links.map((link, index) => {
          const { text, url, onClick, icon, openInNewTab = true } = link
          const Icon = icon
          const linkProps = {}
          Object.assign(linkProps, {
            to: url,
            ...(openInNewTab ? { target: '_blank', rel: 'noreferrer' } : {})
          })

          return (
            <div key={index} className='link'>
              {!!url && (
                <Link {...linkProps}>{text}</Link>
              )}
              {!!onClick && <span onClick={onClick}>{text}</span>}
              {icon && <Icon />}
            </div>
          )
        })}
      </div>
    </div>
  )
}

function GooglePlayDownload () {
  return (
    <GooglePlay
      className='appDownloadLink'
      onClick={() => {
        window.open(GOOGLE_PLAY_LINK, '_blank')
      }}
    />
  )
}

function AppStoreDownload () {
  return (
    <AppStore
      className='appDownloadLink'
      onClick={() => {
        window.open(APP_STORE_LINK, '_blank')
      }}
    />
  )
}

function QRCode () {
  return <div className='qrCode'>{import.meta.env.DEV ? <DevQRCode /> : <ProdQRCode />}</div>
}

/**
 * Footer component
 *
 * @exports
 * @returns {React.ReactElement}
 */
export function Footer (props) {
  const isMobile = useMobileDetect()
  const isLoggedIn = useIsLoggedIn()
  const { toggleZendeskWidget } = useZendesk()

  const LINK_SECTIONS = [
    {
      title: 'Legal',
      links: [
        {
          text: 'Terms of Service',
          url: '/terms-of-service',
          openInNewTab: false
        },
        {
          text: 'Privacy Policy',
          url: '/privacy-policy',
          openInNewTab: false
        }
      ]
    },
    {
      title: 'Information',
      links: [
        {
          text: 'Recording Tips',
          url: '/video-tips',
          openInNewTab: false
        },
        ...(isLoggedIn
          ? [{
              text: 'Report a Bug',
              onClick: () => toggleZendeskWidget()
            }]
          : []),
        {
          text: 'Roadmap',
          url: 'https://roadmap.pb.vision/'
        },
        {
          text: 'Blog',
          url: '/blog'
        }
      ]
    },
    {
      title: 'Social',
      links: [
        {
          text: 'Discord',
          url: 'https://discord.com/invite/N73jSVDpwK',
          icon: DiscordLogo
        },
        {
          text: 'Instagram',
          url: 'https://www.instagram.com/pickleballvisionai/',
          icon: InstagramLogo
        },
        {
          text: 'X (Twitter)',
          url: 'https://x.com/PBvision',
          icon: XLogo
        }
      ]
    }
  ]

  return (
    <Container className={clsx([props.className, { mobile: isMobile }])}>
      <div className='leftSection'>
        <PBVisionLogo />
        <div className='download'>
          <QRCode />
          <div className='appDownloadLinks'>
            <GooglePlayDownload />
            <AppStoreDownload />
          </div>
        </div>
        <div className='scan'>
          <ScanToDownloadArrow className='arrow' />
          <div className='text'>Scan to download the app!</div>
        </div>
      </div>
      <div className='rightSection'>
        <div className='linkSections'>
          {LINK_SECTIONS.map((linkSection, index) => {
            const { title, links } = linkSection
            return <LinkSection key={index} title={title} links={links} />
          })}
        </div>
        <div className='bottom'>© 2024 Pickleball Vision AI Inc., All Rights Reserved</div>
      </div>
    </Container>
  )
}
