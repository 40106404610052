import { styled } from '@mui/material/styles'

export const midnightBlue = '#221d35'
export const lightBlue = '#c7d9e5'
export const white = 'white'
const green = '#00d824'
const orangeRed = '#ff4e02'
const whiteSmoke = '#f0f2f6'

const Container = styled('div')({
  fontSize: '15px',
  lineHeight: '180%',
  color: midnightBlue,
  textAlign: 'left',
  fontFamily: 'Inter, sans-serif',
  fontWeight: '400',
  '& .strong': {
    fontWeight: '700'
  },
  '& .faq-wrapper': {
    paddingTop: '0'
  },
  '& .white-circle': {
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    minWidth: '40px',
    height: '40px',
    display: 'flex',
    boxShadow: '0 16px 24px rgba(0, 0, 0, .1);'
  },
  '& .grey': {
    backgroundColor: whiteSmoke
  },
  '& .text-block-3, .text-block-4': {
    color: white
  },
  '& .process-number': {
    border: `1px solid ${lightBlue}`,
    backgroundColor: white,
    borderRadius: '50%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '72px',
    height: '72px',
    fontSize: '20px',
    display: 'flex',
    boxShadow: '0 16px 24px rgba(0, 0, 0, .1)',
    '&.green': {
      backgroundColor: green
    },
    '&.red': {
      backgroundColor: orangeRed
    }
  },
  '& .split': {
    gridColumnGap: '16px',
    gridRowGap: '50px',
    gridTemplateRows: 'auto',
    gridTemplateColumns: '1fr 1fr',
    gridAutoColumns: '1fr',
    display: 'grid',
    '&.less-space.space-top': {
      gridTemplateRows: 'auto'
    }
  },
  '& .text-span-2': {
    fontWeight: '700'
  },
  '& .no-space-bottom': {
    marginBottom: '0',
    fontSize: '20px'
  },
  '& .space-top': {
    marginTop: '40px'
  },
  '& .space-top-large': {
    marginTop: '80px'
  },
  '& .flex-vertical': {
    gridRowGap: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    display: 'flex',
    '&.space-top-large': {
      alignItems: 'center',
      width: '100%',
      marginTop: '31px'
    }
  },
  '& .process-image': {
    minWidth: '100%'
  },
  '& .feature-block': {
    border: `1px solid ${lightBlue}`,
    backgroundColor: white,
    borderRadius: '8px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '32px',
    padding: '40px',
    display: 'flex',
    boxShadow: '0 60px 100px rgba(96, 107, 146, .1)'
  },
  '& .text-span-4': {
    fontWeight: '600'
  },
  '& .heading-4': {
    textAlign: 'center'
  },
  '& .heading-9': {
    marginTop: '40px',
    textAlign: 'center'
  },
  '& .div-block-9': {
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    display: 'flex'
  },
  '& .limit-small': {
    maxWidth: '360px'
  },
  '& .limit-large': {
    maxWidth: '680px',
    marginBottom: '0',
    fontFamily: 'Inter, sans-serif'
  },
  '& .opacity-dark-text': {
    color: 'rgba(34, 29, 53, .7)',
    paddingTop: '10px',
    paddingBottom: '20px',
    fontWeight: '400',
    lineHeight: '150%'
  },
  '& .medium-text': {
    fontSize: '16px',
    '&.opacity-dark-text': {
      '&.limit-large': {
        textAlign: 'center',
        alignSelf: 'center'
      }
    }
  },
  '& img': {
    verticalAlign: 'middle',
    maxWidth: '100%',
    display: 'inline-block',
    border: '0'
  },
  '& a': {
    color: green,
    textDecoration: 'none'
  },
  '& h1': {
    letterSpacing: '-1px',
    borderBottom: '1px solid #fff',
    marginTop: '0',
    marginBottom: '24px',
    paddingBottom: '25px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '53px',
    fontWeight: '600',
    lineHeight: '110%'
  },
  '& h2': {
    letterSpacing: '-1px',
    marginTop: '0',
    marginBottom: '16px',
    fontSize: '37px',
    fontWeight: '600',
    lineHeight: '120%',
    color: midnightBlue
  },
  '& h4': {
    marginTop: '0',
    marginBottom: '16px',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '120%'
  },
  '& p': {
    marginTop: '0',
    marginBottom: '32px'
  },
  '& .center-content': {
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    '&.center-text': {
      textAlign: 'center',
      '&.margin-top-bottom': {
        borderBottom: `1px solid ${lightBlue}`,
        marginBottom: '67px',
        paddingTop: '60px',
        paddingBottom: '60px'
      }
    }
  },
  '& .center-text': {
    textAlign: 'center',
    '&.medium-text.subead': {
      width: '50vw',
      fontWeight: '400'
    }
  },
  '& .section': {
    borderRadius: '6px',
    paddingTop: '93px',
    paddingBottom: '10px',
    display: 'flex',
    position: 'relative',
    overflow: 'hidden'
  },
  '& .w-container': {
    maxWidth: '940px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&:before': {
      content: '" "',
      gridArea: '1 / 1 / 2 / 2',
      display: 'table'
    },
    '&:after': {
      content: '" "',
      gridArea: '1 / 1 / 2 / 2',
      display: 'table',
      clear: 'both'
    }
  },
  '& .w-row': {
    marginLeft: '-10px',
    marginRight: '-10px',
    '&:before': {
      content: '" "',
      gridArea: '1 / 1 / 2 / 2',
      display: 'table'
    },
    '&:after': {
      content: '" "',
      gridArea: '1 / 1 / 2 / 2',
      display: 'table'
    }
  },
  '& .w-col': {
    float: 'left',
    width: '100%',
    minHeight: '1px',
    paddingLeft: '10px',
    paddingRight: '10px',
    position: 'relative'
  },
  '& .w-col-4': {
    width: '33.3333%'
  },
  '& .container': {
    flexFlow: 'column',
    alignItems: 'stretch',
    maxWidth: '1360px',
    paddingLeft: '132px',
    paddingRight: '132px',
    display: 'flex',
    '&.center': {
      alignItems: 'center'
    },
    '&.medium': {
      maxWidth: '968px'
    }
  },
  '& #uploads': {
    marginBottom: '40px',
    '& p': {
      textAlign: 'center'
    }
  },
  '@media screen and (max-width: 991px)': {
    '& body': {
      fontSize: '15px',
      lineHeight: '180%'
    },
    '& .section': {
      marginTop: '0'
    },
    '& .container': {
      paddingLeft: '32px',
      paddingRight: '32px'
    },
    '& h1': {
      fontSize: '45px'
    },
    '& h2': {
      fontSize: '33px'
    },
    '& .split': {
      gridTemplateColumns: '1fr',
      '&.less-space': {
        gridRowGap: 0
      }
    }
  },
  '@media screen and (max-width: 767px)': {
    '& body': {
      fontSize: '14px',
      lineHeight: '180%'
    },
    '& .container': {
      paddingLeft: '24px',
      paddingRight: '24px'
    },
    '& h1': {
      fontSize: '37px'
    },
    '& h2': {
      fontSize: '30px'
    },
    '& .w-row, .w-container .w-row': {
      marginLeft: '0',
      marginRight: '0'
    },
    '& .w-col': {
      width: '100%',
      left: 'auto',
      right: 'auto'
    },
    '& .feature-block': {
      padding: '24px'
    },
    '& .center-text.medium-text.subead': {
      width: 'unset'
    },
    '& #uploads p': {
      textAlign: 'left'
    }
  },
  '@media screen and (max-width: 479px)': {
    '& .section': {
      marginTop: '0',
      paddingTop: '14px',
      paddingBottom: '14px'
    },
    '& .container': {
      zIndex: '1',
      paddingLeft: '15px',
      paddingRight: '15px',
      position: 'relative'
    },
    '& .w-col': {
      width: '100%'
    }
  }
})

export default Container
