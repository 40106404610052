import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { combineReducers } from 'redux'

import * as anonymousSlice from './anonymous'
import { apisSlice } from './apis'
import * as authSlice from './auth'
import * as processingSlice from './processing'
import * as shotFilterSlice from './shot-filter'
import * as uploadsSlice from './uploads'
import { insightsSlice } from './video'

import { assert } from '@/utils'
import { getAuth } from '@/utils/firebase'

const slices = [
  anonymousSlice,
  apisSlice,
  authSlice,
  insightsSlice,
  processingSlice,
  shotFilterSlice,
  uploadsSlice
]
const reducers = {}
for (const slice of slices) {
  reducers[slice.sliceName ?? slice.reducerPath] = slice.reducer
}
assert(slices.length === Object.keys(reducers).length, 'duplicate reducer name')

const store = configureStore({
  devTools: import.meta.env.DEV,
  middleware: getDefaultMiddleware => {
    const customMiddlewares = []
    for (const slice of slices) {
      if (slice.middleware) {
        customMiddlewares.push(slice.middleware)
      }
    }
    return getDefaultMiddleware().concat(customMiddlewares)
  },
  reducer: combineReducers(reducers)
})

setupListeners(store.dispatch)

// listen for auth changes forever; never unsubscribes
getAuth().onAuthStateChanged((user) => {
  store.dispatch(authSlice.firebaseAuthStateChanged(user))
})

authSlice.initMixpanel(store)

// eslint-disable-next-line import/no-default-export
export default store
