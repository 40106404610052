import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'

import CloudUploadIcon from '@/assets/cloud-upload.svg?react'
import { UploadingDetails } from '@/components/upload'
import transparentPixel from '@/utils/transparent-pixel'

const Message = ({ upload, loggedInUser }) => {
  if (['succeeded', 'failed'].includes(upload.status) && !loggedInUser) {
    return <div className='status success neutral-800'>👈 Get your results!</div>
  }
  if (upload.status === 'uploading' || !loggedInUser) {
    return <div className='status warning'>Do not close the browser window</div>
  }
  if (['succeeded', 'failed'].includes(upload.status) && loggedInUser) {
    return <div className='status success'>It’s ok to navigate away from this page</div>
  }
}

function UploadView ({ upload, loggedInUser }) {
  if (!upload) return null
  return (
    <div className='half preview'>
      <Card className='upload-card'>
        <div className='upload-img'>
          <CardMedia component='img' image={upload.thumbnail ?? transparentPixel} alt={upload.name} />
          {upload.status === 'uploading' ? <CloudUploadIcon /> : <CheckCircleOutlineIcon />}
        </div>
        <div className='column grow'>
          <CardContent>
            <div className='name'>{upload.name}</div>
            <UploadingDetails
              upload={upload}
              countdownPrefix='We’ll email you when your report is ready '
            />
          </CardContent>
        </div>
      </Card>
      <Message upload={upload} loggedInUser={loggedInUser} />
    </div>
  )
}

export default UploadView
